.grid-col-2 {
	max-width: 550px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr;
	gap: 45px 30px;
	@include breakpoint(850px) {
		max-width: 100%;
		grid-template-columns: 1fr 1fr;
	}
	@include breakpoint(1050px) {
		gap: 30px 60px;
	}
}

.grid-col-3 {
	display: grid;
	grid-gap: 30px;
	grid-template-columns: 1fr;
	@include breakpoint(450px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
	.col {
		width: 100%;
		max-width: 400px;
		margin: 0 auto;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	&.align-left .col {
		margin: 0;
	}
}