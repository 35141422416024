/*----------------------------------------------------------*\
    Helpers

    This file contains most of the available helper
    utilities (not including base Foundation). Use these
    when small tweaks are needed on existing pieces that
    don't warrant a full semantic CSS process.

\*----------------------------------------------------------*/


/*------------------------------------*\
    @group Font Helpers
\*------------------------------------*/

.text-xlrg {font-size:1.4375em !important}
.text-lrg {font-size:1.25em !important}
.text-sml {font-size:.75em !important}

.text-shadow {text-shadow: $text-shadow !important;}
.text-shadow-light {text-shadow: $text-shadow-light !important;}

.text-center {text-align: center !important;}
.text-left {text-align: left;}
.text-right {text-align: right;}

@media screen and #{breakpoint(medium)} {
    .text-xlrg {font-size:1.62em !important}
    .text-lrg {font-size:1.27em !important}
    .text-sml {font-size:.75em !important}
}

/* @end */


/*------------------------------------*\
    @group Text Colors
\*------------------------------------*/

.highlight {
	color: $brand;
}
.highlight-alt {
	color: $brand-alt;
	font-weight: 700;
	text-shadow: $text-shadow;
}
@each $name, $color in $colors {
  .text-#{$name} {
    color: $color !important;
  }
}

/* @end */


/*------------------------------------*\
    @group Background Helpers
\*------------------------------------*/

//Background colors from _variables
@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color !important;
  }
}

//Background gradients from _variables
@each $name, $color in $gradients {
  .bg-gradient-#{$name} {
    background: $color !important;
  }
}


.box-shadow {box-shadow: $box-shadow;}

.bg-round {border-radius: $border-round;}

/* @end */


/*------------------------------------*\
    @group Spacing Helpers
\*------------------------------------*/
//set $marginEnd to desired margin range. A mb-# and mt-# class will be generated every 5px between 0 and $marginEnd
$marginStart: 0;
$marginEnd: 50;

@while $marginStart <= $marginEnd {
    .mt-#{$marginStart} {
        margin-top: #{$marginStart}px !important;
    }
    .mb-#{$marginStart} {
        margin-bottom: #{$marginStart}px !important;
    }

    $marginStart: $marginStart + 5;
}

.mb-base {
    margin-bottom: $base-spacing;
}

.mt-base {
    margin-top: $base-spacing;
}

.stacked {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
/* @end */


/*------------------------------------*\
    @group Responsive Map
\*------------------------------------*/
.map-embed {
    height: 0;
    position: relative;
    padding-bottom: 45%; // This is the aspect ratio
    overflow: hidden;
}
.map-embed iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
}
/* @end Responsive Map */

/*------------------------------------*\
    @group Responsive Iframe
\*------------------------------------*/
.responsive-iframe {
    width: 100%;
    height: 480px;
    border:0;
    position:relative;
}
/* @end Responsive Iframe */

/*------------------------------------*\
    @group Social Media brand Colors
\*------------------------------------*/

@each $name, $color in $social {
  .bg-#{$name} {
    background-color: $color !important;
  }
}

@each $name, $color in $social {
  .text-#{$name} {
    color: $color !important;
  }
}

/* @end */


.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}