/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    background-color: $charcoal;
    font-size: rem-calc(14);
    color: $white;
    padding: 60px 0 20px;
    a {
        color: $white;
        text-decoration: none;
        &:hover, &:focus {
            color: #20b5ff; // Adjusted for contrast
        }
    }
    nav {
        font-size: rem-calc(20);
    }
    .inner {
        @include xy-grid-container;
    }
    .logo {
        margin-bottom: 32px;
        img {
            width: auto;
            margin: 0;
        }
    }
    .key-nav {
        margin-bottom: 42px;
        display: flex;
        flex-wrap: wrap;
        ul {
            margin: 0;
            display: inline-block;
            &:first-child {
                margin-right: 42px;
                margin-bottom: 5px;
            }
        }
        li {
            margin-bottom: 8px;
        }
        img {
            min-width: 0;
            margin: 0 auto 24px;
            display: block;
        }
        .award-logo-wrapper {
            flex-basis: 100%;
            margin-top: 24px;
        }
    }
    .extra-nav {
        margin-bottom: 42px;
        ul {
            margin-bottom: 32px;
        }
        li {
            font-size: rem-calc(16);
            margin-bottom: 8px;
        }
    }
    .extra-nav ul > li:first-child,
    .extra-nav li.large-item {
        font-size: rem-calc(20);
        margin-bottom: 8px;
        a {
            color: $white;
            &:hover, &:focus {
                color: $brand-accent;
            }
        }
    }
    .extra-info {
        margin-top: 60px;
    }
    .social-list {
        ul {
            margin: 0;
        }
        li {
            background: none;
            padding: 0;
            margin: 0 24px 24px 0;
            display: inline-block;
            a {
                color: $white;
                transition: color .2s;
                &:hover, &:focus {
                    color: #20b5ff; // Adjusted for contrast;
                }
            }
        }
        svg {
            max-height: 24px;
            width: auto;
            fill: currentColor;
        }
    }
    .legal {
        font-weight: $main-normal;
        .inline {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }
        p {
            margin-bottom: 5px;
        }
    }
}

@include breakpoint(350px) {
    .page-footer .key-nav .award-logo-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;
        img {
            margin: 0;
        }
    }
}

@include breakpoint(medium) {
    .page-footer {
        .footer-navs {
            display: flex;
            justify-content: space-between;
        }
        .key-nav {
            width: 30%;
        }
        .extra-nav {
            width: 67%;
            display: flex;
            justify-content: space-between;
            ul {
                margin-left: auto;
            }
        }
        .extra-info {
            margin-top: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .social li {
            margin-bottom: 0;
        }
        .legal {
            text-align: right;
        }
    }
}

@include breakpoint(large) {
    .page-footer {
        .key-nav {
            width: 48%;
            .award-logo-wrapper {
                flex-wrap: nowrap;
            }
        }
        .extra-nav {
            width: 48%;
        }
    }
}