// Page heading with background image
$bg-heading-gradient: linear-gradient(180deg,#0a0a0a,transparent);

.page-heading {
	height: 250px;
	background-color: $dark-charcoal;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	display: flex;
	align-items: flex-end;
	position: relative;
	&::after {
		content: '';
		background-image: linear-gradient(to top, black 0%, rgba(0, 0, 0, 0.738) 19%, rgba(0, 0, 0, 0.541) 34%, rgba(0, 0, 0, 0.382) 47%, rgba(0, 0, 0, 0.278) 56.5%, rgba(0, 0, 0, 0.194) 65%, rgba(0, 0, 0, 0.126) 73%, rgba(0, 0, 0, 0.075) 80.2%, rgba(0, 0, 0, 0.042) 86.1%, rgba(0, 0, 0, 0.021) 91%, rgba(0, 0, 0, 0.008) 95.2%, rgba(0, 0, 0, 0.002) 98.2%, transparent 100%);
		width: 100%;
		height: 225px;
		opacity: .8;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	@include breakpoint(980px) {
		height: 310px;
		&::before {
			content: '';
			background-image: linear-gradient(to bottom, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.84) 18%, rgba(0,0,0,0.69) 36%, rgba(0,0,0,0.50) 54%, rgba(0,0,0,0.30) 100%);
			width: 100%;
			height: 100%;
			opacity: .9;
			position: absolute;
			left: 0;
			top: 0;
		}
		&::after {
			display: none;
		}
	}
	.inner {
		width: 100%;
		@include xy-grid-container;
		position: relative;
		z-index: 50;
	}
	.inner-content {
		max-width: 1000px;
	}
	h1 {
		background: rgba(0, 0, 0, 0.70);
		font-size: rem-calc(24);
		color: $white;
		line-height: 1.2;
		text-transform: uppercase;
		padding: 15px 20px 10px;
		margin: 0;
		display: inline-block;
		@include breakpoint(large) {
			font-size: rem-calc(44);
		}
		+ p {
			margin-top: -17px;
		}
	}
	p {
		font-size: rem-calc(25);
		color: $white;
		line-height: 1.28;
		@include breakpoint(large) {
			font-size: rem-calc(34);
			line-height: 1;
		}
	}
}

.bg-page-heading-1 {
	background-image: url('https://archrock.imgix.net/headers/twilight_parked_trucks.jpg?auto=format');
	@include breakpoint(medium) {
		background-image: url('https://archrock.imgix.net/headers/twilight_parked_trucks_med.jpg?auto=format');
	}
	@include breakpoint(980px) {
		background-image: url('https://archrock.imgix.net/headers/twilight_parked_trucks_lrg.jpg?auto=format');
	}
}

.bg-page-heading-2 {
	background-image: url('https://archrock.imgix.net/headers/compressor_ladder_view.jpg?auto=format');
	@include breakpoint(medium) {
		background-image: url('https://archrock.imgix.net/headers/compressor_ladder_view_med.jpg?auto=format');
	}
	@include breakpoint(980px) {
		background-image: url('https://archrock.imgix.net/headers/compressor_ladder_view_lrg.jpg?auto=format');
	}
}

.bg-page-heading-3 {
	background-image: url('https://archrock.imgix.net/headers/compressor_line.jpg?auto=format');
	@include breakpoint(medium) {
		background-image: url('https://archrock.imgix.net/headers/compressor_line_med.jpg?auto=format');
	}
	@include breakpoint(980px) {
		background-image: url('https://archrock.imgix.net/headers/compressor_line_lrg.jpg?auto=format');
	}
}

.bg-page-heading-4 {
	background-image: url('https://archrock.imgix.net/headers/man_gloves_equipment.jpg?auto=format');
	@include breakpoint(medium) {
		background-image: url('https://archrock.imgix.net/headers/man_gloves_equipment_med.jpg?auto=format');
	}
	@include breakpoint(980px) {
		background-image: url('https://archrock.imgix.net/headers/man_gloves_equipment_lrg.jpg?auto=format');
	}
}

.bg-page-heading-5,
.bg-wrench-closeup {
	background-image: url('https://archrock.imgix.net/headers/wrench_closeup.jpg?auto=format');
	@include breakpoint(medium) {
		background-image: url('https://archrock.imgix.net/headers/wrench_closeup_med.jpg?auto=format');
	}
	@include breakpoint(980px) {
		background-image: url('https://archrock.imgix.net/headers/wrench_closeup_lrg.jpg?auto=format');
	}
}

.bg-men-talking-sunset {
	background-image: url('https://archrock.imgix.net/headers/men_talking_sunset.jpg?auto=format');
	@include breakpoint(medium) {
		background-image: url('https://archrock.imgix.net/headers/men_talking_sunset_med.jpg?auto=format');
	}
	@include breakpoint(980px) {
		background-image: url('https://archrock.imgix.net/headers/men_talking_sunset_lrg.jpg?auto=format');
	}
}

.bg-dusk-warehouse-trucks {
	background-image: url('https://archrock.imgix.net/headers/dusk_warehouse_trucks.jpg?auto=format&w=700');
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		background-image: url('https://archrock.imgix.net/headers/dusk_warehouse_trucks.jpg?auto=format&w=700&dpr=2&q=5');
	}
	@include breakpoint(medium) {
		background-image: url('https://archrock.imgix.net/headers/dusk_warehouse_trucks_med.jpg?auto=format&w=1050');
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('https://archrock.imgix.net/headers/dusk_warehouse_trucks_med.jpg?auto=format&w=1050&dpr=2&q=5');
		}
	}
	@include breakpoint(980px) {
		background-image: url('https://archrock.imgix.net/headers/dusk_warehouse_trucks_lrg.jpg?auto=format&w=1600');
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('https://archrock.imgix.net/headers/dusk_warehouse_trucks_lrg.jpg?auto=format&w=1600&dpr=2&q=5');
		}
	}
}