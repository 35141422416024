.video-grid {
	.video-grid-item {
		max-width: $contained-width;
		margin: 0 auto 60px;
		&:last-child {
			margin-bottom: 0;
		}
		h3 {
			font-size: rem-calc(30);
			line-height: 1.1;
			margin: 0 0 17px;
		}
	}
	.video-preview {
		margin: 0 0 30px;
		position: relative;
		transition: transform .15s;
		img {
			margin: 0;
		}
		svg {
			color: $yellow;
			position: absolute;
			right: 15px;
			bottom: 15px;
			transition: color .3s, transform .15s;;
		}
		&:hover {
			cursor: pointer;
			box-shadow: 0 0 10px rgba($black, .2);
			transform: scale(1.02);
			svg {
				color: lighten($yellow, 30%);
				transform: scale(1.02);
			}
		}
	}
}

@include breakpoint(500px) {
	.video-grid {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
		grid-column-gap: 3%;
	}
}

@include breakpoint(800px) {
	.video-grid {

		.video-grid-item {
			margin-bottom: (90px - $base-spacing);
		}
		.video-preview {
			svg {
				right: 30px;
				bottom: 30px;
				transition: color .3s;
			}
		}
	}
}


.video-highlight {
	background-color: $charcoal;
	color: $white;
	max-width: 800px;
	margin: 0 auto;
	img {
		margin-bottom: 0;
	}
	a {
		color: $yellow;
		&:focus, &:hover {
			color: lighten($yellow, 30%);
		}
	}
}
.video-highlight-media {
	position: relative;
	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
.video-highlight-text {
	padding: 20px;
	h2 {
		font-size: rem-calc(24);
		color: $white;
		margin-bottom: 10px;
	}
	p {
		line-height: 1.3;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(medium) {
	.video-highlight-text {
		padding: 25px;
		h2 {
			font-size: rem-calc(27);
		}
	}
}