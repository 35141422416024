.split-highlight-list {
	max-width: 500px;
	margin: 0 auto $content-sub-section-spacing-small;
	&:last-child {
		margin-bottom: 0;
	}
	&:only-child {
		margin-bottom: $content-sub-section-spacing-small;
	}
	.intro {
		margin-bottom: 36px;
		h2 {
			color: $black;
			margin-bottom: 24px;
		}
	}
	.list {
		h3, .h3 {
			color: $brand-alt;
			margin: 0 0 8px;
			padding-bottom: 5px;
			border-bottom: solid 2px $light-aluminum;
		}
	}
}

@include breakpoint(large) {
	.split-highlight-list {
		max-width: 100%;
		margin-bottom: $content-sub-section-spacing;
		display: flex;
		justify-content: space-between;
		&:only-child {
			margin-bottom: $content-sub-section-spacing;
		}
		.intro {
			width: 40%;
			margin: 0;
		}
		.list {
			width: 46%;
		}
	}
}