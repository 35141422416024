.photo-collage {
	max-width: 1000px;
	display: flex;
	margin: 0 auto ($content-sub-section-spacing-small - $base-spacing);
	flex-wrap: wrap;
	align-items: flex-end;
	@include breakpoint(medium) {
		margin-bottom: ($content-sub-section-spacing - $base-spacing);
	}
	&.section-spacing {
		margin-bottom: 0;
	}
	img {
		margin: 0;
	}
	.caption {
		font-size: rem-calc(12);
		font-style: italic;
		color: $charcoal;
		line-height: 1;
		margin-bottom: 8px;
		@include breakpoint(medium) {
			font-size: rem-calc(16);
		}
		@include breakpoint(large) {
			font-size: rem-calc(18);
		}
	}
	.left-photo {
		width: 55%;
		flex-shrink: 1;
		margin-right: 4%;
	}
	.right-photo {
		width: 40%;
		flex-shrink: 1;
	}
	.bottom-photo {
		max-width: 65%;
		margin: 4% auto $base-spacing;
	}

}

.photo-collage-thirds {
	margin-bottom: 15px;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 15px;
	img {
		margin: 0;
	}
}

@include breakpoint(400px) {
	.photo-collage-thirds {
		grid-template-columns: .35fr .65fr;
		&.flip {
			grid-template-columns: .65fr .35fr;
		}
	}
}

@include breakpoint(large) {
	.photo-collage-thirds {
		margin-bottom: 30px;
		grid-gap: 30px;
	}
}