/*----------------------------------------------------------*\
	Callouts

	Some of the callout styles come from the Foundation
	settings file. The styles listed here apply to the
	default callouts. Additional callout options can be
	seen on the styleguide.

\*----------------------------------------------------------*/

.callout {
	background: $brand;
	.heading, h3 {
		font-size: 1.4375em;
		color: $white;
	}
	p {
		font-size: rem-calc(14);
		line-height: 1.25;
		margin: 0 0 1rem;
	}
	.button {
		font-size: 1rem;
		padding: .5rem 1rem;
		margin: 0;
	}
}


// Callout with angled image on the left
.angled-callout {
	width: 100%;
	margin-bottom: 60px;
	@include breakpoint(large) {
		margin-bottom: 90px;
	}
	&.section-spacing {
		margin-bottom: $base-spacing;
	}
	.text {
		max-width: 450px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 36px;
	}
	.media {
		text-align: center;
		img {
			width: auto;
			margin: 0;
		}
	}
}
@include breakpoint(medium) {
	.angled-callout {
		max-width: 100%;
		display: flex;
		align-items: center;
		.text {
			order: 1;
			padding-left: 30px;
			@include breakpoint(1200px) {
				margin-left: 65px;
			}
		}
		.media {
			margin-left: -30px;
			flex-shrink: 0;
			order: 0;
			@include breakpoint(large) {
				margin-left: -15px;
			}
			@include breakpoint(1200px) {
				margin-left: 0;
			}
		}
		&.shrink-media .media {
			flex-shrink: 1;
		}
	}

	.angled-callout.flip {
		.text {
			padding-left: 0;
			padding-right: 0;
			order: 0;
		}
		.media {
			margin-right: -30px;
			margin-left: 0;
			@include breakpoint(large) {
				margin-right: -15px;
				margin-left: 0;
			}
			@include breakpoint(1200px) {
				margin-right: 0;
			}
		}
	}
}

// Inline heading callout
.simple-heading-callout {
	h2 {
		font-size: rem-calc(37);
		line-height: 1;
		margin: 0 0 16px;
	}
	p {
		font-size: rem-calc(25);
		line-height: 1.28;
	}
}

@include breakpoint(large) {
	.simple-heading-callout {
		display: flex;
		justify-content: space-between;
		h2 {
			font-size: rem-calc(53);
			line-height: .94;
			margin: 0 16px 0;
		}
		p {
			font-size: rem-calc(26);
			line-height: 1.23;
		}
		.heading {
			max-width: 300px;
		}
		.text {
			max-width: 650px;
		}
	}
}

//Alert callout for forms
.alert.callout {
	background-color: $red;
	color: $white;
	padding: 10px 20px;
	margin-bottom: 20px;
	p {
		font-size: 18px;
		margin: 0;
	}
}

//Message callout
.message-callout {
	background-color: $light-aluminum;
	padding: 30px;
	margin-bottom: $content-sub-section-spacing-small;
	border-radius: 4px;
	@include breakpoint(medium) {
		margin-bottom: $content-sub-section-spacing;
	}
	> :last-child {
		margin-bottom: 0;
	}
	h2 {
		margin-bottom: 20px;
	}
}

// Image download callout
.image-download-callouts {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 60px;
}

.image-download-callout {
	padding: 50px 30px;
	border: solid 1px $light-aluminum;
	text-align: center;
	h2 {
		margin-bottom: 30px;
	}
	img {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
		display: block;
		margin: 0 auto 40px;
	}
	.button {
		margin-bottom: 0;
	}
}

// Callout with background gradient over image
$bg-callout-gradient: linear-gradient(0deg, #446039 0%, rgba(68, 96, 57, 0.00) 100%);

.callout-bg-gradient {
	max-width: 580px;
	background-color: #446039;
	color: $white;
	margin: 0 auto $base-spacing;
	.image {
		width: 100%;
		background-image: url('#{$imgcdn}/callouts/archrock_maintenance_facility.jpg?auto=format&w=580');
		background-size: cover;
		padding: 40px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		img {
			max-width: 200px;
			margin: 0;
			position: relative;
			z-index: 10;
		}
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			background-image: $bg-callout-gradient;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}
	}
	&.bg-men-compressor .image {
		background-image: url('#{$imgcdn}/callouts/men_compressor_laptop.jpg?auto=format&w=580');
	}
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		.image {
			background-image: url('#{$imgcdn}/callouts/archrock_maintenance_facility.jpg?auto=format&w=580&dpr=2&q=20');
		}
		&.bg-men-compressor .image {
			background-image: url('#{$imgcdn}/callouts/men_compressor_laptop.jpg?auto=format&w=580&dpr=2&q=20');
		}
	}
	.text {
		padding: 0 30px 30px;
	}
	h2 {
		color: $white;
		margin-bottom: 12px;
	}
	.button {
		margin-bottom: 0;
	}
}

@include breakpoint(medium) {
	.callout-bg-gradient-container {
		@include xy-grid-container;
	}
}

@include breakpoint(930px) {
	.callout-bg-gradient {
		max-width: 100%;
		display: flex;
		align-items: center;
		.image {
			flex-basis: 40%;
			order: 1;
			align-self: stretch;
			&::before {
				background-image: linear-gradient(90deg, #446039 0%, rgba(68, 96, 57, 0.00) 100%);
			}
		}
		.text {
			flex-basis: 60%;
			padding-top: 30px;
		}
	}
}

@include breakpoint(1000px) {
	.callout-bg-gradient {
		min-height: 380px;
		.image {
			flex-basis: 50%;
			img {
				max-width: 100%;
			}
		}
		.text {
			flex-basis: 50%;
			padding: 40px 40px 40px 60px;
		}
	}
}