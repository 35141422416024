.form-section {
	margin-bottom: 30px;
	h5 {
		text-transform: none;
	}
	.checkbox {
		margin: 0 0 16px;
		display: flex;
		align-items: center;
		input {
			margin: 0;
		}
	}
	.checkbox-list {
		margin-bottom: 16px;
	}
}
.contact-options {
	padding-top: 60px;
	border-top: 1px solid #979797;
	margin-top: 66px;
	.contact-form {
		max-width: 485px;
		flex-shrink: 0;
		.field-row-2 {
			display: flex;
			justify-content: space-between;
			.field {
				width: 45%;
			}
		}
	}
	.social-options {
		margin: 66px 0;
		h3, .h3 {
			color: $brand-alt;
			margin: 0 0 8px;
			padding-bottom: 5px;
			border-bottom: solid 2px $light-aluminum;
		}
		svg {
			width: auto;
			height: 40px;
			fill: currentColor;
		}
		.social-icon-list {
			margin-top: 16px;
		}
		.social-icon {
			margin-right: 30px;
			&:hover, &:focus {
				color: $yellow;
			}
		}
		.glassdoor {
			color: map-get($social, glassdoor);
		}
		.twitter {
			color: $black;
			.accent-color {
				color: $black;
			}
		}
		.facebook {
			color: map-get($social, facebook);
		}
		.linkedin {
			color: map-get($social, linkedin);
		}
	}
}

@include breakpoint(900) {
	.contact-options {
		display: flex;
		.contact-text {
			padding-right: 10%;
		}
	}
}

.social-links-section {
	margin-bottom: 32px;
	h3, .h3 {
		font-size: rem-calc(14);
		color: $brand-alt;
		margin: 0 0 8px;
	}
	svg {
		width: auto;
		height: 40px;
		fill: currentColor;
	}
	.social-icon-list {
		margin-top: 16px;
	}
	.social-icon {
		margin-right: 30px;
		margin-bottom: 10px;
		&:hover, &:focus {
			color: $yellow;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	.glassdoor {
		color: map-get($social, glassdoor);
	}
	.twitter {
		color: $black;
	}
	.facebook {
		color: map-get($social,facebook);
	}
	.linkedin {
		color: map-get($social,linkedin);
	}
	.instagram {
		color: map-get($social,instagram);
	}
}

@include breakpoint(medium) {
	.social-links-section {
		margin-bottom: 0;
	}
	.headquarter-locations {
		display: flex;
		.location {
			flex-shrink: 0;
			padding-left: 5%;
		}
	}
}

@include breakpoint(large) {
	.headquarter-locations {
		.text {
			max-width: 600px;
		}
	}
}
