.media-aside {
	max-width: 800px;
	margin: 0 auto $base-spacing;
	.media {
		margin-bottom: 40px;
		p {
			font-size: rem-calc(20);
			line-height: 1.33;
		}
	}
	.aside {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		grid-gap: 24px 30px;
	}
	.box {
		background-color: $off-white;
		padding: 20px;
		> *:last-child {
			margin-bottom: 0;
		}
		.heading {
			font-size: rem-calc(27);
			line-height: 1;
			margin-bottom: 14px;
		}
	}
	.video-link {
		margin-bottom: $base-spacing;
		display: block;
		position: relative;
		color: $yellow;
		&:hover, &:focus-visible {
			cursor: pointer;
			img {
				transform: scale(1.02);
			}
			svg {
				color: lighten($yellow, 30%);
			}
		}
		img {
			margin: 0;
			transition: transform .3s;
		}
		svg {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: color .3s;
		}
	}
}

@include breakpoint(800px) {
	.media-aside {
		max-width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 30px;
		.media {
			flex-basis: 794px;
			margin-bottom: 0;
			p {
				font-size: rem-calc(24);
			}
		}
		.aside {
			flex-basis: 382px;
		}
	}
}

@include breakpoint(1100px) {
	.media-aside {
		.box {
			padding: 25px;
		}
	}
}