.text-grid {
}
.text-grid-item {
	max-width: $contained-width;
	margin: 0 0 60px;
	.icon {
		color: $brand-accent;
		line-height: 1;
		text-align: center;
		margin-right: 10px;
		svg {
			width: auto;
			height: 30px;
		}
	}
	h3 {
		display: flex;
		margin-bottom: 14px;
		align-items: center;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

@include breakpoint(medium) {
	.text-grid {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.text-grid-item {
		width: 43%;
		&:nth-last-child(-n +2) {
			margin-bottom: 0;
		}
	}
}

.text-grid.simple-text {
	h2 {
		margin-bottom: 60px;
	}
	h3 {
		text-align: left;
	}
}

.archrock-roles-section {
	background-image: url('https://archrock.imgix.net/cssimg/bg_topo_map_gray.jpg?auto=format&w=700');
	background-repeat: no-repeat;
	background-position: center top;
	@include breakpoint(medium) {
		background-image: url('https://archrock.imgix.net/cssimg/bg_topo_map_gray_med.jpg?auto=format&w=1050');
	}
	@include breakpoint(large) {
		background-image: url('https://archrock.imgix.net/cssimg/bg_topo_map_gray_lrg.jpg?auto=format&w=2000');
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.archrock-roles-section {
		background-image: url('https://archrock.imgix.net/cssimg/bg_topo_map_gray.jpg?auto=format&w=700&dpr=2&q=20');
		background-size: 700px 1000px;
		@include breakpoint(medium) {
			background-image: url('https://archrock.imgix.net/cssimg/bg_topo_map_gray_med.jpg?auto=format&w=1050&dpr=2&q=20');
			background-size: 1050px 1000px;
		}
		@include breakpoint(large) {
			background-image: url('https://archrock.imgix.net/cssimg/bg_topo_map_gray_lrg.jpg?auto=format&w=2000&dpr=2&q=20');
			background-size: 2000px 812px;
		}
	}
}