.angled-highlight {
	background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor.png?auto=format&w=450');
	background-repeat: no-repeat;
	background-position: center bottom;
	padding-left: 1.875rem;
	padding-right: 1.875rem;
	padding-bottom: 460px;
	margin-bottom: $content-sub-section-spacing-small;
	&.single-section {
		padding-top: $content-sub-section-spacing-small;
	}
	.text {
		max-width: 450px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 36px;
		h2 {
			font-size: rem-calc(30);
			line-height: 1.1;
			@include breakpoint(1150px) {
				font-size: rem-calc(34);
				line-height: 1;
				margin: 0 0 24px;
			}
		}
	}
}

.angled-highlight.flip {
	background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_left.png?auto=format&w=450');
}

@include breakpoint(medium) {
	.angled-highlight {
		max-width: 1500px;
		min-height: 552px;
		background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_med.png?auto=format&w=270');
		background-position: right center;
		padding-bottom: $content-sub-section-spacing-small;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: $content-sub-section-spacing;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.text {
			margin-left: 0;
			@include breakpoint(1350px) {
				max-width: 700px;
			}
		}
	}
	.angled-highlight.flip {
		background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_left_med.png?auto=format&w=270');
		background-position: left center;
		.text {
			margin-left: auto;
			margin-right: 0;
		}
	}
}

@include breakpoint(950px) {
	.angled-highlight {
		background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_lrg.png?auto=format&w=507');
		.text {
			padding-left: .9375rem;
		}
	}
	.angled-highlight.flip {
		background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_left_lrg.png?auto=format&w=507');
		.text {
			padding-left: 0;
			padding-right: .9375rem;
		}
	}
}

@include breakpoint(1150px) {
	.angled-highlight {
		background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_xlrg.png?auto=format&w=750');
		min-height: 1007px;
		padding-left: .9375rem;
		padding-right: .9375rem;
	}
	.angled-highlight.flip {
		background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_left_xlrg.png?auto=format&w=750');
	}
}

.angled-highlight {
	&.compressor-internals {
		background-image: url('https://archrock.imgix.net/highlights/compressor_internals.png?auto=format&w=450');
		@include breakpoint(medium) {
			background-image: url('https://archrock.imgix.net/highlights/compressor_internals_med.png?auto=format&w=270');
		}
		@include breakpoint(900px) {
			background-image: url('https://archrock.imgix.net/highlights/compressor_internals_lrg.png?auto=format&w=507');
		}
		@include breakpoint(1150px) {
			background-image: url('https://archrock.imgix.net/highlights/compressor_internals_xlrg.png?auto=format&w=750');
		}
	}
	&.twilight-pipes {
		background-image: url('https://archrock.imgix.net/highlights/twilight_pipes.png?auto=format&w=450');
		@include breakpoint(medium) {
			background-image: url('https://archrock.imgix.net/highlights/twilight_pipes_med.png?auto=format&w=270');
		}
		@include breakpoint(900px) {
			background-image: url('https://archrock.imgix.net/highlights/twilight_pipes_lrg.png?auto=format&w=507');
		}
		@include breakpoint(1150px) {
			background-image: url('https://archrock.imgix.net/highlights/twilight_pipes_xlrg.png?auto=format&w=750');
		}
	}
	&.man-repairing-equipment {
		background-image: url('https://archrock.imgix.net/highlights/man_repairing_equipment_right.png?auto=format&w=450');
		@include breakpoint(medium) {
			background-image: url('https://archrock.imgix.net/highlights/man_repairing_equipment_right_med.png?auto=format&w=270');
		}
		@include breakpoint(900px) {
			background-image: url('https://archrock.imgix.net/highlights/man_repairing_equipment_right_lrg.png?auto=format&w=507');
		}
		@include breakpoint(1150px) {
			background-image: url('https://archrock.imgix.net/highlights/man_repairing_equipment_right_xlrg.png?auto=format&w=750');
		}
	}
	&.man-in-sunset {
		background-image: url('https://archrock.imgix.net/highlights/man_in_sunset_right.png?auto=format&w=450');
		@include breakpoint(medium) {
			background-image: url('https://archrock.imgix.net/highlights/man_in_sunset_right_med.png?auto=format&w=270');
		}
		@include breakpoint(900px) {
			background-image: url('https://archrock.imgix.net/highlights/man_in_sunset_right_lrg.png?auto=format&w=507');
		}
		@include breakpoint(1150px) {
			background-image: url('https://archrock.imgix.net/highlights/man_in_sunset_right_xlrg.png?auto=format&w=750');
		}
	}
}

.angled-highlight.flip {
	&.truck-sunset {
		background-image: url('https://archrock.imgix.net/highlights/truck_sunset.png?auto=format&w=450');
		@include breakpoint(medium) {
			background-image: url('https://archrock.imgix.net/highlights/truck_sunset_med.png?auto=format&w=270');
		}
		@include breakpoint(900px) {
			background-image: url('https://archrock.imgix.net/highlights/truck_sunset_lrg.png?auto=format&w=507');
		}
		@include breakpoint(1150px) {
			background-image: url('https://archrock.imgix.net/highlights/truck_sunset_xlrg.png?auto=format&w=750');
		}
	}
}



@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.angled-highlight {
		background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor.png?auto=format&w=450&dpr=2&q=20');
		background-size: 395px 460px;
		@include breakpoint(medium) {
			background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_med.png?auto=format&w=270&dpr=2&q=20');
			background-size: 270px 552px;
		}
		@include breakpoint(900px) {
			background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_lrg.png?auto=format&w=507&dpr=2&q=20');
			background-size: 507px 552px;
		}
		@include breakpoint(1150px) {
			background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_xlrg.png?auto=format&w=750&dpr=2&q=20');
			background-size: 750px 1007px;
		}
		&.compressor-internals {
			background-image: url('https://archrock.imgix.net/highlights/compressor_internals.png?auto=format&w=450&dpr=2&q=20');
			@include breakpoint(medium) {
				background-image: url('https://archrock.imgix.net/highlights/compressor_internals_med.png?auto=format&w=270&dpr=2&q=20');
			}
			@include breakpoint(900px) {
				background-image: url('https://archrock.imgix.net/highlights/compressor_internals_lrg.png?auto=format&w=507&dpr=2&q=20');
			}
			@include breakpoint(1150px) {
				background-image: url('https://archrock.imgix.net/highlights/compressor_internals_xlrg.png?auto=format&w=750&dpr=2&q=20');
			}
		}
		&.man-repairing-equipment {
			background-image: url('https://archrock.imgix.net/highlights/man_repairing_equipment_right.png?auto=format&w=450&dpr=2&q=20');
			@include breakpoint(medium) {
				background-image: url('https://archrock.imgix.net/highlights/man_repairing_equipment_right_med.png?auto=format&w=270&dpr=2&q=20');
			}
			@include breakpoint(900px) {
				background-image: url('https://archrock.imgix.net/highlights/man_repairing_equipment_right_lrg.png?auto=format&w=507&dpr=2&q=20');
			}
			@include breakpoint(1150px) {
				background-image: url('https://archrock.imgix.net/highlights/man_repairing_equipment_right_xlrg.png?auto=format&w=750&dpr=2&q=20');
			}
		}
		&.man-in-sunset {
			background-image: url('https://archrock.imgix.net/highlights/man_in_sunset_right.png?auto=format&w=450&dpr=2&q=20');
			@include breakpoint(medium) {
				background-image: url('https://archrock.imgix.net/highlights/man_in_sunset_right_med.png?auto=format&w=270&dpr=2&q=20');
			}
			@include breakpoint(900px) {
				background-image: url('https://archrock.imgix.net/highlights/man_in_sunset_right_lrg.png?auto=format&w=507&dpr=2&q=20');
			}
			@include breakpoint(1150px) {
				background-image: url('https://archrock.imgix.net/highlights/man_in_sunset_right_xlrg.png?auto=format&w=750&dpr=2&q=20');
			}
		}
	}
	.angled-highlight.flip {
		background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_left.png?auto=format&w=450&dpr=2&q=20');
		background-size: 395px 460px;
		@include breakpoint(medium) {
			background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_left_med.png?auto=format&w=270&dpr=2&q=20');
			background-size: 270px 552px;
		}
		@include breakpoint(900px) {
			background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_left_lrg.png?auto=format&w=507&dpr=2&q=20');
			background-size: 507px 552px;
		}
		@include breakpoint(1150px) {
			background-image: url('https://archrock.imgix.net/highlights/woman_fixing_compressor_left_xlrg.png?auto=format&w=750&dpr=2&q=20');
			background-size: 750px 1007px;
		}
		&.truck-sunset {
			background-image: url('https://archrock.imgix.net/highlights/truck_sunset.png?auto=format&w=450&dpr=2&q=20');
			@include breakpoint(medium) {
				background-image: url('https://archrock.imgix.net/highlights/truck_sunset_med.png?auto=format&w=270&dpr=2&q=20');
			}
			@include breakpoint(900px) {
				background-image: url('https://archrock.imgix.net/highlights/truck_sunset_lrg.png?auto=format&w=507&dpr=2&q=20');
			}
			@include breakpoint(1150px) {
				background-image: url('https://archrock.imgix.net/highlights/truck_sunset_xlrg.png?auto=format&w=750&dpr=2&q=20');
			}
		}
	}
}