/* Metric Light */
@font-face {
  font-family: 'Metric';
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url('/fonts/MetricLight.eot'); /* IE9 Compat Modes */
  src: url('/fonts/MetricLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/MetricLight.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/MetricLight.woff') format('woff'), /* Modern Browsers */
       url('/fonts/MetricLight.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/MetricLight.svg#MetricLight') format('svg'); /* Legacy iOS */
}

/* Metric Medium */
@font-face {
  font-family: 'Metric';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url('/fonts/MetricMedium.eot'); /* IE9 Compat Modes */
  src: url('/fonts/MetricMedium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/MetricMedium.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/MetricMedium.woff') format('woff'), /* Modern Browsers */
       url('/fonts/MetricMedium.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/MetricMedium.svg#MetricMedium') format('svg'); /* Legacy iOS */
}

/* Metric Bold */
@font-face {
  font-family: 'Metric';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url('/fonts/MetricBold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/MetricBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/MetricBold.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/MetricBold.woff') format('woff'), /* Modern Browsers */
       url('/fonts/MetricBold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/MetricBold.svg#MetricBold') format('svg'); /* Legacy iOS */
}

/* Factoria Black */
@font-face {
  font-family: 'factoriablack';
  font-style: normal;
  font-weight: normal;
  font-display: fallback;
  src: url('/fonts/factoria-black-webfont.eot'); /* IE9 Compat Modes */
  src: local('Factoria Black'),
       url('/fonts/factoria-black-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/factoria-black-webfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/factoria-black-webfont.woff') format('woff'), /* Modern Browsers */
       url('/fonts/factoria-black-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/factoria-black-webfont.svg#factoriablack') format('svg'); /* Legacy iOS */
}