.media-section {
    background-color: $dark-charcoal;
    color: $white;
    padding: $full-section-spacing-small 0 ($full-section-spacing-small - $base-spacing);
    .section-heading {
        color: $white;
    }
    .media-section-inner {
        max-width: 600px;
        margin: 0 auto;
    }
    .media {
        text-align: center;
        margin-bottom: (60px - $base-spacing);
        img {
            width: 100%;
            margin: 0 0 $base-spacing;
        }
    }
    .text {
        .heading {
            font-size: rem-calc(30);
            color: $white;
            line-height: 1.1;
            margin: 0 0 13px;
        }
    }
    &.bg-map-lines {
        background-image: url('https://archrock.imgix.net/cssimg/bg_topo_map.jpg?auto=format&w=700');
        background-size: cover;
        @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
        only screen and (-o-min-device-pixel-ratio: 13/10),
        only screen and (min-resolution: 120dpi) {
            background-image: url('https://archrock.imgix.net/cssimg/bg_topo_map.jpg?auto=format&w=700&dpr=2&q=20');
        }
        @include breakpoint(700px) {
            background-image: url('https://archrock.imgix.net/cssimg/bg_topo_map_med.jpg?auto=format&w=1050');
            @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
            only screen and (-o-min-device-pixel-ratio: 13/10),
            only screen and (min-resolution: 120dpi) {
                background-image: url('https://archrock.imgix.net/cssimg/bg_topo_map_med.jpg?auto=format&w=1050&dpr=2&q=40');
            }
        }
        @include breakpoint(1050px) {
            background-image: url('https://archrock.imgix.net/cssimg/bg_topo_map_lrg.jpg?auto=format&w=1600');
            @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
            only screen and (-o-min-device-pixel-ratio: 13/10),
            only screen and (min-resolution: 120dpi) {
                background-image: url('https://archrock.imgix.net/cssimg/bg_topo_map_lrg.jpg?auto=format&w=1600&dpr=2&q=40');
            }
        }
    }
}

@include breakpoint(large) {
    .media-section {
        padding: $full-section-spacing 0 ($full-section-spacing - $base-spacing);
        .media-section-inner {
            max-width: 100%;
            display: flex;
            align-items: center;
        }
        .media {
            text-align: left;
            margin: 0;
            flex-shrink: 0;
            padding-right: 27px;
        }
        .text {
            .heading {
                font-size: rem-calc(44);
            }
        }
    }
}