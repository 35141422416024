/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    background: $white;
    padding: $content-sub-section-spacing-small 0 ($content-sub-section-spacing-small - $base-spacing); 
    @include breakpoint(large) {
    	padding: $content-sub-section-spacing 0 ($content-sub-section-spacing - $base-spacing);
    }
}
.page-content-top {
    background: $white;
    padding: $content-sub-section-spacing-small 0 0;
    @include breakpoint(large) {
        padding: $content-sub-section-spacing 0 0;
    }
}
.inner {
    @include xy-grid-container;
    &.wide {
        max-width: 1500px;
    }
    &.narrow {
        max-width: 800px;
    }
    &.collapse {
        padding: 0;
    }
}
