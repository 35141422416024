/*----------------------------------------------------------*\
    Lists

    In addition to standard list styles, a list with icons
    applied as ::before pseudo-elements is implemented.
    To add additional icons, follow the examples below.
    The split-list-* classes will make your list into
    multiple columns on larger views while remaining a
    single column on smaller views.

\*----------------------------------------------------------*/

ul {
	padding: 0;
	margin: 18px 0;
	li {
		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='50' cy='50' r='50'/%3E%3C/svg%3E");
		background-size: 8px;
		background-repeat: no-repeat;
		background-position: 4px 9px;
		line-height: 1.35;
		padding: 0 0 0 28px;
		margin: 0 0 11px;
		list-style: none;
		position: relative;
		&:last-child {
			margin-bottom: 0 !important;
		}
	}
	li ul {
		margin: .666em 0 .75em;
		li {
			padding-left: 24px;
		}
	}
}
ol {
	margin: 20px 0 15px 20px;
	li {
		line-height: 1.4;
		margin: 0 0 14px;
	}
	ol {
		list-style: lower-alpha;
	}
	ol ol {
		list-style: lower-roman;
	}
}
ul.bare {
	li {
		background: none;
		padding-left: 0;
		position: relative;
	}
}
ul.icon-list {
	li {
		padding-left: 25px;
		&::before {
			content: '\f000';
			font-family: $icon-font;
			font-size: 16px;
			color: $brand;
			position: absolute;
			left: 0;
			top: 0;
		}
		li::before {
			display: none;
		}
		a.open, a:hover, a:focus {
			color: $brand;
		}
	}
	.ico-plug::before {
		content: '\f00d';
	}
	.ico-controller::before {
		content: '\f00e';
	}
	.ico-paper-video::before {
		content: '\f00f';
	}
	.ico-cloud::before {
		content: '\f010';
	}
	.ico-phone::before {
		content: '\f011';
	}
	.ico-newspaper::before {
		content: '\f012';
	}
	.ico-book::before {
		content: '\f013';
	}
}

ul.tax-form-list {
	.date {
		min-width: 87px;
		color: $brand-alt;
		margin-right: 12px;
		display: inline-block;
	}
	.icon {
		vertical-align: middle;
		display: inline-block;
		margin-right: 5px;
		svg {
			height: 20px;
			fill: $brand-alt;
		}
	}
}


.text-sml li {
	background-position: 4px 7px;
	background-size: 6px;
	padding-left: 20px;
}

@media screen and #{breakpoint(medium)} {
	ul.split-list-2,
	ol.split-list-2 {
		margin-left: 0;
		padding-left: 0;
		overflow: hidden;
	}
	ul.split-list-2 li,
	ol.split-list-2 li {
		float: left;
		width: 50%;
		padding-right: 5%;
	}
	ul.split-list-2 li:nth-child(2n+1),
	ol.split-list-2 li:nth-child(2n+1) {
		clear:left;
	}
	ol.split-list-2 li {
		margin-right: 2%;
	}
	ul.split-list-3,
	ol.split-list-3 {
		padding-left: 0;
		margin-left: 0;
		overflow: hidden;
	}
	ul.split-list-3 li,
	ol.split-list-3 li {
		float: left;
		width: 33%;
		padding-right: 2%;
	}
	ol.split-list-3 li {
		margin-right: 2%;
	}
	ul.split-list-3 li:nth-child(3n+1),
	ol.split-list-3 li:nth-child(3n+1) {
		clear:left;
	}
}

/*------------------------------------*\
    @group Nav Reset
\*------------------------------------*/
nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		background: none;
		padding: 0;
		margin: 0;
	}
	li ul {
		margin: 0;
	}
	li ul li {
		background: none;
		margin: 0;
	}
}
/* @end */