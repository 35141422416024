.angled-section-container {
	overflow: hidden;
}
.angled-box-wrapper {
	@include xy-gutters($gutter-position: left right, $gutter-type: margin);
	@include breakpoint(medium) {
		margin: 0;
	}
}

.angled-box {
	max-width: 480px;
	background-color: $charcoal;
	margin: 0 auto 70px;
	box-shadow: 10px 10px 0 $teal;
	padding: 30px;
	position: relative;
	.text {
		background-color: $charcoal;
	}
	&.section-spacing {
		margin-bottom: ($base-spacing + 10px);
	}
	&.yellow {
		box-shadow: 10px 10px 0 $yellow;
	}
	&.blue {
		box-shadow: 10px 10px 0 $brand-accent;
	}
	@include breakpoint(medium) {
		background: none;
		max-width: 100%;
		width: 85%;
		box-shadow: none;
		padding: 30px 30px 30px 120px;
		margin-left: auto;
		margin-right: 0;
		&.yellow::before {
			box-shadow: 10px 10px 0 $yellow;
		}
		&.blue::before {
			box-shadow: 10px 10px 0 $brand-accent;
		}
		&::before {
			content: '';
			width: 150%;
			background-color: $charcoal;
			height: 100%;
			position: absolute;
			box-shadow: 10px 10px 0 $teal;
			z-index: 0;
			top: 0;
			left: 0;
			transform: skew(-18deg);
		}
	}
	@include breakpoint(large) {
		padding: 60px 52px 60px 103px;
		margin-bottom: 100px;
	}
	@include breakpoint(1700px) {
		width: calc(50% + 600px);
	}
	.inner-content {
		max-width: 900px;
		position: relative;
		z-index: 100;
	}
	.button {
		margin-bottom: 0;
	}
	h2 {
		color: $white;
		margin: 0 0 13px;
	}
	p {
		font-size: rem-calc(20);
		color: $white;
		line-height: 1.28;
	}
}

.angled-box.anchor-left {
	box-shadow: -10px 10px 0 $teal;
	&.yellow {
		box-shadow: -10px 10px 0 $yellow;
	}
	&.blue {
		box-shadow: -10px 10px 0 $brand-accent;
	}
	@include breakpoint(medium) {
		background: none;
		max-width: 100%;
		width: 85%;
		box-shadow: none;
		padding: 30px 120px 30px 30px;
		margin-left: 0;
		margin-right: auto;
		&.yellow::before {
			box-shadow: -10px 10px 0 $yellow;
		}
		&.blue::before {
			box-shadow: -10px 10px 0 $brand-accent;
		}
		&::before {
			content: '';
			width: 150%;
			background-color: $charcoal;
			height: 100%;
			position: absolute;
			box-shadow: -10px 10px 0 $teal;
			z-index: 0;
			top: 0;
			right: 0;
			left: auto;
			transform: skew(18deg);
		}
		.inner-content {
			margin-left: auto;
			margin-right: 0;
		}
	}
	@include breakpoint(medium) {
		padding: 60px 120px 60px 30px;
	}
	@include breakpoint(1700px) {
		width: calc(50% + 600px);
	}
}

@include breakpoint(850px) {
	.angled-box {
		.inner-content {
			display: flex;
			align-items: center;
			.text > *:last-child {
				margin-bottom: 0;
			}
		}
		.action {
			flex-shrink: 0;
			margin-left: 60px;
		}
	}
}