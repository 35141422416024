.content-columns-section {
    background-color: $dark-blue;
    color: $white;
    padding: $full-section-spacing-small 0 ($full-section-spacing-small - $base-spacing);
    &.no-base {
        padding-bottom: $full-section-spacing-small;
    }
    .main-heading {
        color: $white;
        text-align: center;
        margin: 0 0 55px;
        span {
            font-family: $main-font;
            font-size: rem-calc(16);
            color: $light-aluminum;
            line-height: 1.25;
            text-transform: uppercase;
            margin: 0 0 10px;
            display: block;
        }
    }
    .content-columns {
        display: grid;
        grid-template-columns: 1fr;
        gap: 50px;
        margin-bottom: $base-spacing;
    }
    .content-column {
        margin-bottom: 0;
        > *:last-child {
            margin-bottom: 0;
        }
        .heading {
            color: $white;
            padding-bottom: 19px;
            margin: 0 0 20px;
            position: relative;
            &::after {
                content: '';
                background-color: $brand-accent;
                width: 80px;
                height: 3px;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            &.bare {
                padding-bottom: 0;
                &::after {
                    display: none;
                }
            }
        }
    }
    .angled-box {
        margin-top: 36px;
        margin-bottom: 10px;
    }
}

@include breakpoint(850px) {
    .content-columns-section {
        padding: $full-section-spacing 0 ($full-section-spacing - $base-spacing);
        &.no-base {
            padding-bottom: $full-section-spacing;
        }
        .content-columns {
           grid-template-columns: 1fr 1fr;
           gap: 60px 70px;
        }
        .angled-box {
            margin-top: ($content-sub-section-spacing - $base-spacing);
        }
    }
}

// Equal width double columns
.double-col {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 52px;
    @include breakpoint(400px) {
        grid-template-columns: repeat(auto-fit,minmax(280px, 1fr));
    }
}