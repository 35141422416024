// .slide-in-left {
//   @include mui-slide(in, $direction: left, $duration: .3s);
// }

// .slide-out-right {
// 	@include mui-slide(out, $direction: right, $duration: .2s);
// }

.animate-fade-in {
  @include mui-fade(in, $duration: .3s);
}

.animate-fade-out {
	@include mui-fade(out, $duration: .2s);
}