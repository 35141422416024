.cookie-notification-bar {
	width: 100%;
	background: $deep-navy;
	color: $white;
	box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
	padding: 20px 0;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 499;
	.button {
		margin-bottom: 0;
	}
	.inner {
		max-width: 1000px;
	}
}

@include breakpoint(medium) {
	.cookie-notification-bar .inner {
		display: flex;
		align-items: center;
		p {
			margin-bottom: 0;
			padding-right: 45px;
		}
		.button {
			flex-shrink: 0;
		}
	}
}