/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/

body {
	background: $white;
	width: 100%;
	font-family: $main-font;
	font-size: 20px;
	font-weight: 300;
	line-height: 1.35;
	color: $main-color;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body.offscreen-nav-visible {
	position: fixed;
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background: $brand;
		opacity: .85;
		z-index: 1500;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

p {
	font-size: 1em;
	line-height: 1.35;
	margin: 0 0 24px;
	&.intro {
		font-size: rem-calc(18);
	}
}
a:not(.button) {
	background-color: transparent;
	color: $link-base;
	transition: text-decoration-color .3s, color .3s;
	text-decoration-line: underline;
	text-decoration-thickness: 2px;
	text-decoration-color: $main-color;
	text-underline-offset: 3px;
	&:hover,
	&:focus {
		text-decoration-color: $link-base;
	}
	&.yellow-link {
		color: $yellow;
		text-decoration-color: $white;
		text-decoration-thickness: 1px;
		text-underline-offset: 4px;
		&:hover,
		&:focus {
			color: $yellow;
			text-decoration-color: $yellow;
		}
	}
	&.alt {
		color: $main-color;
		text-decoration-color: $brand-accent;
		&:hover, &:focus {
			text-decoration-color: $link-base;
		}
	}
}

a.pdf-download-link {
	min-height: 35px;
	background: url('/images/icons/download_pdf.svg') no-repeat left 4px;
	padding-left: 30px;
	display: inline-block;
}

hr {
	margin: 20px 0;
}

@media screen and #{breakpoint(medium)} {
	body {
		line-height: 1.5;
	}
	p {
		line-height: 1.5;
	}
}

/* @end */