.section-intro {
	.heading-image {
		margin: 0 auto 30px;
		display: block;
	}
	p {
		font-size: rem-calc(23);
		line-height: 1.28;
		@include breakpoint(large) {
			font-size: rem-calc(26);
			line-height: 1.23;
		}
	}
	ul {
		font-size: rem-calc(23);
		line-height: 1.28;
		li {
			background-position: 4px 12px;
		}
		@include breakpoint(large) {
			font-size: rem-calc(26);
			line-height: 1.23;
			li {
				background-position: 4px 14px;
			}
		}
	}
}
.section-intro.font-large p {
	font-size: rem-calc(23);
	line-height: 1.28;
	@include breakpoint(large) {
		font-size: rem-calc(26);
		line-height: 1.23;
	}
}

.section-intro.narrow {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
}

.section-intro.inline-button {
	max-width: 560px;
	margin: 0 auto;
}

@include breakpoint(520px) {
	.section-intro.inline-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			padding-right: 20px;
		}
		.button {
			flex-shrink: 0;
		}
	}
}