.image-grid {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: ($content-sub-section-spacing-small - 30px);
}
.image-grid-item {
	width: 47%;
	padding: 25px 25px;
	margin-bottom: 30px;
	box-shadow: 0 0 4px rgba(0,0,0, .2);
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		max-height: 180px;
		margin: 0;
	}
}

@include breakpoint(medium) {
	.image-grid {
		justify-content: space-around;
		margin-bottom: ($content-sub-section-spacing - 60px);
	}
	.image-grid-item {
		width: 21%;
		margin-bottom: 60px;
	}
}