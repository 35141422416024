/*----------------------------------------------------------*\
    Forms

    The majority of the form styling comes from the
    Foundation settings file. Any new forms added that
    need specific styling (like search forms as seen here)
    should be added in this file.

\*----------------------------------------------------------*/

form {
	input[type="text"],
	input[type="email"],
	select,
	textarea {
		&:hover {
			border-color: $brand-accent;
		}
	}
	label {
		margin-bottom: 6px;
	}
	textarea {
		min-height: 150px;
	}
	.invalid-required, .invalid-email {
	    border: red 1px solid;
	}
	label.invalid-required, label.invalid-email {
	    border: 0;
	}

	legend.invalid-required, legend.invalid-email {
		color: red;
	    border: 0;
	}

	.invalid-required[type="checkbox"] + label {
		color: red;
	}

	/* by default, only the invalid-* classes are added to the field, using a pseudo element or adding custom error messages with a Javascript event listener is recommended.  */
	label.invalid-required::after,
	label.invalid-email::after {
	    content: "*this field is required";
	    font-size: 12px;
	    color: red;
	    margin-top: -10px;
	    margin-bottom: 20px;
	    display: block;
	}
	label.invalid-email::after {
	    content: "*the email address is not properly formatted.";
	}
}

.checkbox-list.inline-labels label {
	display: flex;
	align-items: center;
	margin: 0;
	input[type="checkbox"] {
		margin-right: 7px;
	}
}


//Us Locations Map Search Form
.map-search-form {
	.field-group {
		display: flex;
		input[type="text"] {
			max-width: 300px;
			margin-right: 20px;
		}
	}
}

// Form with background
.form-bg {
	background-color: $dark-charcoal;
	color: $white;
	padding: 30px;
	h2 {
		font-size: rem-calc(26);
		color: $white;
	}
	label {
		color: $white;
	}
	.field-row-2 {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
		grid-column-gap: 20px;
	}
	.field-full {
		grid-column: 1 / -1;
	}
	p.icon {
		padding-left: 45px;
		&.ico-phone {
			background: url('/images/icons/phone.svg') no-repeat left center;
		}
		&.ico-email {
			background: url('/images/icons/email.svg') no-repeat left center;
		}
	}
	a {
		color: $yellow;
		text-decoration-color: $white;
		text-decoration-thickness: 1px;
		text-underline-offset: 4px;
		&:hover, &:focus {
			color: $yellow;
			text-decoration-color: $yellow;
		}
	}
}