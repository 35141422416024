.link-callout {
	max-width: 415px;
	font-size: rem-calc(18);
	border: solid 1px $light-aluminum;
	padding: 20px;
	margin: 0 auto 24px;
	display: flex;
	.icon {
		flex-shrink: 0;
	}
	.text {
		padding-left: 12px;
	}
	img {
		margin: 0;
	}
	p {
		font-size: rem-calc(18);
		line-height: 1.11;
		margin: 0;
	}
}

.link-callout.wide {
	max-width: 500px;
	flex-wrap: wrap;
	justify-content: center;
	img {
		margin: 0 auto 10px;
	}
}

.link-callout.text-only {
	justify-content: center;
}

@include breakpoint(500px) {
	.link-callout.wide {
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
		img {
			margin: 0;
		}
	}
}


.inline-link-callouts {
	display: flex;
	flex-wrap: wrap;
	&.tiled {
		.link-callout {
			margin-left: 24px;
			margin-right: 24px;
		}
	}
	&.center {
		justify-content: center;
		align-items: flex-start;
		gap: 20px 30px;
		.link-callout {
			margin: 0;
		}
	}
}