.social-media-angle-callout {
	max-width: 380px;
	background-color: $dark-blue;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	h3 {
		font-family: $main-font;
		font-size: rem-calc(20);
		font-weight: $main-normal;
		color: $white;
		margin-bottom: 5px;
	}
	a {
		text-decoration: none;
		padding: 30px 44px 40px;
		position: relative;
		z-index: 200;
		display: block;
	}
	a:hover, a:focus {
		.media img {
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.75);
			transform: scale(1.05);
		}
	}
	img {
		margin: 0;
	}
	.heading {
		margin-bottom: 22px;
	}
	.media {
		img {
			box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
			transition: transform .2s, box-shadow .2s;
		}
	}
}

.social-media-angle-callout.facebook {background-color: #3b5998;}
.social-media-angle-callout.linkedin {background-color: #4875b4;}
.social-media-angle-callout.twitter {background-color: #000000;}
.social-media-angle-callout.instagram {background-color: #7638fa;}

.social-media-angle-callouts {
	display: grid;
	grid-column-gap: 30px;
	grid-row-gap: 45px;
	justify-content: center;
}

@include breakpoint(440px) {
	.social-media-angle-callout {
		&::after {
			content: "";
			background-color: #FFFFFF;
			height: 150%;
			width: 100%;
			display: block;
			position: absolute;
			right: -82%;
			top: -33%;
			transform: rotate(-18deg);
			z-index: 100;
		}
	}
}

@include breakpoint(750px) {
	.social-media-angle-callouts {
		grid-template-columns: repeat(2, 1fr);
	}
}

@include breakpoint(1100px) {
	.social-media-angle-callouts {
		// grid-template-columns: repeat(4, 1fr);
	}
}