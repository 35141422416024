/*----------------------------------------------------------*\
   Tables

	The majority of the table styling comes from the
    Foundation settings file.

\*----------------------------------------------------------*/

table {
	margin-bottom: 2rem;
	th {
		font-size: 1.125em;
		line-height: 1.2;
		border: solid 1px $white;
	}
	td {
		border: solid 1px $white;
	}
}