.stock-info {
	font-size: rem-calc(18);
	font-weight: 500;
	color: $white;
	.stock-exchange {
		color: #8c8c8c;
	}
	.stock-symbol {
		padding-left: 5px;
	}
	.stock-change {
		padding-left: 20px;
		svg {
			fill: currentColor;
		}
	}
	.stock-trade {
		padding-left: 20px;
	}
	.stock-time {
		font-size: 75%;
		color: #8c8c8c;
		padding-left: 12px;
	}
}