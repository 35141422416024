.back-to-top {
	background-color: $brand-accent-ally;
	width: 40px;
	height: 40px;
	color: #FFFFFF;
	position: fixed;
	border-radius: 4px;
	padding: 10px;
	right: 20px;
	bottom: 20px;
	opacity: 0;
	visibility: hidden;
	transition: opacity .3s;
	display: flex;
	justify-content: center;
	cursor: pointer;
	z-index: 500;
	img {
		margin: 0;
	}
	p {
		line-height: 1.35;
	}
}

.show-back-to-top {
	opacity: 1;
	visibility: visible;
}