/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/

.page-header {
	background-color: $charcoal;
	.inner {
		padding: 0;
		position: relative;
	}
    .large-nav {
    	display: none;
	}
	.menu-heading {
		color: $brand-alt;
		font-weight: $main-normal;
		cursor: text;
		&.pad-top {
			padding-top: 20px;
			display: inline-block;
		}
	}
    .nav-toggle {
    	font-weight: $main-normal;
    	color: $white;
    	text-transform: uppercase;
    	display: flex;
    	align-items: center;
    	a, .nav-label {
    		color: $white;
			text-decoration: none;
    		margin-left: 5px;
    		cursor: pointer;
    		display: inline-block;
    		transition: color .3s;
    		&:hover, &:focus {
    			color: $brand-accent;
    		}
    	}
    	&:hover a, &:focus a {
    		color: $brand-accent;
    	}
    	&.active .nav-open {
    		display: none;
    	}
    	&.active .nav-close {
    		display: block;
    	}
    	.nav-close {
    		display: none;
    	}
    }
	.stock-info-small {
		@include xy-grid;
		@include xy-gutters($gutter-position: left right, $gutter-type: padding);
		min-height: 46px;
		background-color: $black;
		text-align: right;
		padding-top: 11px;
		padding-bottom: 11px;
		align-items: flex-end;
		justify-content: flex-end;
	}
	.logo-container {
		@include xy-grid;
		background-color: $charcoal;
		padding: 15px 0;
		align-items: center;
		justify-content: space-between;
		img {
			margin: 0;
		}
    	.logo {
    		@include xy-gutters($gutter-position: left right, $gutter-type: padding);
    		img {
    			max-width: 126px;
    		}
    	}
    	.nav-toggle {
    		@include xy-gutters($gutter-position: left right, $gutter-type: padding);
    	}
    	.stock-info, .cta-button, .search-link {
    		display: none;
    	}
	}
	.search-link {
		button {
			height: 35px;
			width: 35px;
			color: $white;
			line-height: 1;
			border: solid 2px rgba($white, .35);
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: background-color .3s;
			&:hover, &:focus {
				background-color: rgba($white, .35);
			}
			svg {
				height: 15px;
			}
		}
	}
	.stock-info {
		.stock-change {
			.indicator {
				display: inline-block;
				margin-right: 8px;
			}
			&.increase {
				color: #57a300;
			}
			&.decrease svg {
				transform: rotate(180deg);
				margin-top: -10px;
			}
		}
	}
}

@media only screen and (min-width: 38.5625em) {
	.page-header {
		.stock-info-small {
			display: none;
		}
		.logo-container {
			.stock-info {
				// @include xy-gutters($gutter-position: left right, $gutter-type: padding);
				margin-left: auto;
				display: block;
			}
			.logo img {
				max-width: 100%;
			}
		}
	}
}

@media only screen and (min-width: 65.625em) {
	.page-header {
		width: 100%;
		background-color: transparent;
		position: absolute;
		z-index: 201;
		> .inner {
			@include xy-grid-container($padding: 0);
			z-index: 150;
			position: relative;
		}
		.nav-toggle {
			display: none !important;
		}
	    .large-nav {
	    	@include xy-gutters($gutter-position: left right, $gutter-type: padding);
	    	display: block;
	    }
	    .logo-container {
	    	background-color: transparent;
			padding: 26px 0 15px;
			.cta-button {
				margin-left: 12px;
				display: block;
				@include xy-gutters($gutter-type: padding);
				.button {
					margin-bottom: 0;
				}
			}
			.search-link {
				margin-left: 24px;
				display: flex;
			}
	    }
	}
}

/*------------------------------------*\
    @group Navigation
\*------------------------------------*/

.large-nav {
	.top-level {
		font-size: rem-calc(16);
		font-weight: $main-normal;
		> li {
			display: inline-block;
			cursor: pointer;
			position: relative;
			&:first-child {
				margin-left: -20px;
			}
			&.dropdown:hover > a, &.dropdown:focus > a {
				background-color: $dark-charcoal;
				transition: color .25s, background-color .25s, transform .25s;
			}
			> a {
				display: block;
				text-transform: uppercase;
				padding: 20px 15px;
				transition: color .75s, background-color .75s, transform .75s;
			}
			&.dropdown:hover,
			&.dropdown:focus,
			&.dropdown:focus-within {
				cursor: pointer;
				> a::after {
					transform: rotate(-180deg);
					transition: transform .5s;
				}
				> ul {
					visibility: visible;
					opacity: 1;
					transition: opacity .25s, visibility .25s;
				}
			}
			&.selected > a {
				color: $brand-accent;
			}
		}
		li.dropdown > a {
			padding-right: 30px;
			&::after {
				content: '';
				background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNODgyLjczIDEyNC4wOGwtNC42Mi00LjU4YS4zNy4zNyAwIDAgMSAwLS41M2wuNjItLjYyYS4zOC4zOCAwIDAgMSAuNTMgMGwzLjc0IDMuNyAzLjc0LTMuN2EuMzguMzggMCAwIDEgLjUzIDBsLjYyLjYyYy4xNS4xNC4xNS4zOCAwIC41M2wtNC42MiA0LjU4YS4zOC4zOCAwIDAgMS0uNTQgMHoiLz48L2RlZnM+PHVzZSBmaWxsPSIjMDBhOWUwIiB4bGluazpocmVmPSIjYSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTg3OCAtMTE4KSIvPjwvc3ZnPg==');
				background-repeat: no-repeat;
				width: 10px;
				height: 7px;
				display: block;
				position: absolute;
				top: 29px;
				right: 10px;
				transition: transform .5s;
			}
		}
	}
	li.selected > a {
		color: $brand-accent;
	}
	li.dropdown > ul {
		background-color: $dark-charcoal;
		width: 320px;
		font-size: rem-calc(20);
		font-weight: $main-light;
		padding: 20px;
		position: absolute;
		top: 100%;
		visibility: hidden;
		opacity: 0;
		transition: opacity .75s, visibility .75s;
		z-index: 50;
		li {
			line-height: 1.2;
			margin-bottom: 7px;
			padding: 0;
		}
		> li {
			margin-bottom: 10px;
		}
		> li:last-child > ul {
			margin-bottom: 0;
		}
		ul {
			font-size: rem-calc(18);
			margin: 10px 0 30px 15px;
		}
	}
	a:not(.button) {
		color: $white;
		text-decoration: none;
		&:hover, &:focus {
			color: $brand-accent;
		}
	}
	.button {
		text-decoration: none;
		margin-left: 20px;
		margin-bottom: 0;
	}
	li.nav-button {
		padding: 0;
		a {
			background: $brand-accent-ally;
			font-size: rem-calc(16);
			color: $white;
			line-height: 1;
			padding: 10px 15px;
			display: block;
			&:hover, &:focus {
				background-color: darken($brand-accent-ally, 5%);
				color: $white;
			}
		}
		&.selected > a {
			color: $white;
		}
	}
}

@include breakpoint(1240px) {
	.large-nav {
		.top-level {
			font-size: rem-calc(18);
			> li > a {
				padding: 20px 19px;
			}
		}
		li.nav-button {
			a {
				font-size: rem-calc(18);
				padding: 20px;
			}
		}
	}
}
/* @end Navigation */