/*------------------------------------*\
    @group Hero
\*------------------------------------*/

// Tall Hero
.hero-large {
	background-size: cover;
	background-position: center;
	background-color: $charcoal;
	height: 440px;
	overflow-x: hidden;
	position: relative;
	@include breakpoint(medium) {
		height: 570px;
	}
	@include breakpoint(large) {
		height: 1000px;
	}
	.angled-box {
		background: none;
		max-width: 90%;
		padding: 20px 52px;
		margin: 0 0 0 auto;
		position: absolute;
		z-index: 10;
		bottom: 30px;
		right: 0;
		@include breakpoint(medium) {
			max-width: 66%;
		}
		@include breakpoint(large) {
			max-width: 50%;
			bottom: 90px;
		}
		h1 {
			max-width: 580px;
			margin: 0;
			z-index: 150;
		}
	}
	.hero-content {
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $black 100%);
		width: 100%;
		color: $white;
		padding-bottom: 54px;
		text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
		position: absolute;
		bottom: 0;
		z-index: 200;
		@include breakpoint(large) {
			padding-bottom: 90px;
		}
		.inner-content {
			@include xy-grid-container;
			padding-top: 52px;
		}
		.content-wrap {
			max-width: 520px;
			@include breakpoint(large) {
				max-width: 800px;
			}
			> *:last-child {
				margin-bottom: 0;
			}
		}
	}
	h1 {
		font-size: rem-calc(24);
		color: $white;
		line-height: 1.2;
		text-transform: uppercase;
		margin: 0 0 24px;
		@include breakpoint(large) {
			font-size: rem-calc(44);
		}
		+ p {
			margin-top: -17px;
		}
	}
	p {
		font-size: rem-calc(25);
		line-height: 1.28;
		@include breakpoint(large) {
			font-size: rem-calc(34);
			line-height: 1;
		}
	}
}


// Video Hero
.hero-video {
	background-color: $charcoal;
	position: relative;
	z-index: 10;
	.angled-box {
		&::before {
			content: '';
			width: 150%;
			background-color: $charcoal;
			height: 100%;
			position: absolute;
			box-shadow: 10px 10px 0 #00b398;
			z-index: 0;
			top: 0;
			left: 0;
			transform: skew(-18deg);
		}
	}
	.video {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		z-index: -1;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	video {
		object-fit: contain;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		left: 0;
		right: 0;
		top: auto;
		bottom: 0;
		display: block;
	}
}

@include breakpoint(1050px) {
	.hero-video {
		background-position: center top;
	}
	.hero-shim {
		&::before {
			content:'';
			background-image: linear-gradient(180deg, $black 0%, rgba(0, 0, 0, 0) 100%);
			width: 100%;
			height: 440px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 100;
		}
	}
}


// Split Hero
.hero-split {
	background-color: $charcoal;
	background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.2) 50%, rgba(0,0,0,0) 50%);
	color: $light-aluminum;
	padding: $content-sub-section-spacing-small 0 ($content-sub-section-spacing-small - $base-spacing) 0;
	@include breakpoint(61.25em) {
		background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.2) 40%,rgba(0,0,0,0) 40%);
		padding: 240px 0 ($content-sub-section-spacing - $base-spacing);
	}
	.inner-content {
		@include xy-grid-container;
		@include breakpoint(medium) {
			display: flex;
			justify-content: space-between;
		}
	}
	.hero-heading {
		margin-bottom: 30px;
		@include breakpoint(medium) {
			width: 45%;
		}
		@include breakpoint(61.25em) {
			width: 40%;
		}
	}
	.hero-content {
		z-index: 200;
		@include breakpoint(medium) {
			width: 45%;
		}
		@include breakpoint(61.25em) {
			width: 55%;
		}
	}
	h1 {
		font-size: rem-calc(30);
		line-height: 1;
		color: $white;
		margin: 0 0 5px;
		@include breakpoint(61.25em) {
			font-size: rem-calc(44);
			line-height: .94;
		}
	}
	.subheading {
		font-family: $heading-font;
		font-size: rem-calc(20);
		color: $brand-alt;
		line-height: 1.35;
		margin: 0;
		.highlight {
			color: $white;
		}
	}
}


// Hero with gradient bg
.hero-gradient {
	height: 440px;
	background-size: cover;
	background-position: center;
	background: linear-gradient(180deg, #001F0F 0%, #002A15 50%, #004E26 100%) #69844b;
	padding-bottom: 50px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	@include breakpoint(large) {
		height: 630px;
	}
	.hero-content {
		width: 100%;
		color: $white;
		text-align: center;
		@include xy-grid-container;
	}
	h1 {
		font-size: rem-calc(30);
		color: $white;
		line-height: 1.1;
		text-transform: uppercase;
		margin: 0;
		@include breakpoint(large) {
			font-size: rem-calc(44);
		}
	}
	img {
		max-width: 250px;
		margin-bottom: 30px;
		@include breakpoint(large) {
			max-width: max-content;
		}
	}
}



//Loops over $hero-bg in _variables.scss and creates files with mixin
@each $name, $filename in $hero-bg {
  .#{$name} {
	@include hero-image($filename: $filename);
	background-color: map-get($hero-bg-color, $name);
  }
}

/* @end Hero */


//Sub-hero boxes
.sub-hero-boxes {
	background-color: $charcoal;
	color: $white;
	padding: 45px 0;
	h2 {
		font-size: rem-calc(22);
		color: $yellow;
		margin: 0 0 7px;
	}
	p {
		margin: 0 0 15px;
	}
	.box {
		max-width: 400px;
		padding-bottom: 30px;
		margin: 0 auto 30px;
		border-bottom: solid 1px rgba(127, 127, 127, 0.25);
		> *:last-child {
			margin-bottom: 0;
		}
		&:last-child {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}
}

@include breakpoint(900px) {
	.sub-hero-boxes {
		.inner {
			display: flex;
		}
		.box {
			width: 33.333%;
			padding: 0 45px 0 0;
			margin: 0 45px 0 0;
			border-bottom: none;
			border-right: solid 1px rgba(127, 127, 127, 0.25);
			&:last-child {
				padding-right: 0;
				margin-right: 0;
				border-right: none;
			}
		}
	}
}

// Sub hero navigation
.sub-hero-nav {
	background-color: $dark-charcoal;
	padding: 60px 0 55px;
	h2 {
		font-size: rem-calc(20);
		color: $white;
		text-align: center;
		line-height: 1.25;
		padding-bottom: 15px;
		margin-bottom: 21px;
		position: relative;
		&::after {
			content: '';
			background-color: $brand-accent;
			width: 80px;
			height: 3px;
			display: inline-block;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	p {
		font-size: rem-calc(30);
		color: $white;
		line-height: 1.1;
		text-align: center;
		margin-bottom: 32px;
	}
}

.sub-hero-nav-links {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 40px;
	@include breakpoint(500px) {
		grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
		grid-gap: 30px 20px;
	}
	// @include breakpoint(1000px) {
	// 	grid-template-columns: repeat(6, 1fr);
	// }
}

.sub-hero-nav-item {
	font-family: $alt-font;
	font-weight: $alt-normal;
	text-align: center;
	a {
		font-size: rem-calc(16);
		color: $yellow;
		text-decoration: none;
		transition: color .3s;
		&:hover, &:focus {
			color: $brand-accent;
		}
	}
	span {
		line-height: 1.25;
		display: block;
	}
	.icon-wrapper {
		background-color: #444444;
		width: 70px;
		height: 70px;
		border-radius: 10px;
		margin: 0 auto 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			margin: 0;
		}
	}
}

// Hero tab bottom modifier
.hero-tab-bottom {
	.hero-content {
		background: rgba(0, 0, 0, 0.60);
		padding: 20px 20px 15px;
		.inner-content {
			padding: 0;
		}
		.content-wrap {
			max-width: 900px;
		}
	}
}