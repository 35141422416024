.alert-bar {
	background-color: $dark-blue;
	color: $white;
	padding: 13px 0 16px;
	.inner {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
	p {
		font-weight: $main-normal;
		line-height: 1.35;
		text-align: center;
		padding: 0 20px;
		margin-bottom: 7px;
	}
	.button {
		flex-shrink: 0;
		margin-bottom: 0;
	}
	a {
		font-weight: $main-normal;
		color: $yellow;
		line-height: 1.35;
		text-decoration-line: underline;
		text-decoration-thickness: 2px;
		text-decoration-color: transparent;
		transition: text-decoration-color .1s;
		&:hover, &:focus {
			text-decoration-color: $yellow;
		}
	}
}

@include breakpoint(800px) {
	.alert-bar {
		padding: 13px 0 16px;
		.inner {
			flex-wrap: nowrap;
		}
		p {
			text-align: left;
			padding-right: 20px;
			margin-bottom: 0;
		}
	}
}