.carbon-hawk-patent-info {
    max-width: 350px;
    margin: 0 auto;
    img {
        margin-bottom: 20px;
    }
    p {
        font-size: rem-calc(13);
        line-height: 1.23;
    }
}