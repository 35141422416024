$nav-bg-color: $charcoal;

body.offscreen-nav-visible {
	position: fixed;
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background: $black;
		opacity: .75;
		z-index: 1500;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.offscreen-nav {
	width: 100%;
	max-width: 450px;
	height: 100%;
	background-color: $nav-bg-color;
	padding: 20px;
	box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.15);
	position: fixed;
	top: 0;
	right: 0;
	z-index: 250;
	transform: translateX(100%);
	transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	visibility: hidden;
	overflow: scroll;
	&.visible {
		z-index: 2100;
		visibility: visible;
		transform: translateX(0);
		transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	}
	a, button {
		background: none;
		color: $white;
		text-decoration: none;
		border-bottom: 0;
		cursor: pointer;
		transition: color .3s;
		&:hover,
		&:focus-visible {
			color: $brand-accent;
		}
	}
	a.selected,
	button.selected {
		color: $brand-accent;
	}
	.dropdown > li a {
		padding: 2px 16px 2px 0;
	}
	.has-dropdown {
		display: flex;
		align-items: center;
		justify-content: space-between;
		button {
			width: 100%;
			text-align: left;
			display: block;
			&.active::after {
				transform: rotate(180deg);
			}
		}
	}
	.top-level {
		li {
			font-size: rem-calc(22);
			font-weight: $main-normal;
			border-top: solid 1px #545353;
			margin-bottom: 0;
			padding: 10px 0 10px 16px;
			&.nav-button {
				border-top: none;
				padding: 0;
				text-align: center;
				a {
					background: $brand-accent-ally;
					font-size: rem-calc(18);
					color: $white;
					line-height: 1;
					padding: 20px;
					display: block;
					&:hover, &:focus {
						background-color: darken($brand-accent-ally, 5%);
						transition: background-color .25s;
						color: $white;
					}
				}
			}
			&.has-children.active {
				background-color: $dark-charcoal;
				transition: background-color .3s;
			}
		}
		.nav-text {
			display: inline-block;
			font-family: $main-font;
			font-size: rem-calc(22);
			font-weight: $main-normal;
		}
		.nav-caret {
			width: 32px;
			height: 30px;
			color: $brand-alt;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				margin-bottom: 0;
				transition: transform .3s;
			}
			&.active img,
			&:hover img {
				transform: rotate(180deg);
			}
		}
	}
	.second-level {
		li {
			font-size: rem-calc(20);
			line-height: 1.2;
			padding: 0;
			padding-bottom: 10px;
			border-top: none;
			&:first-child {
				padding-top: 10px;
			}
			&:last-child {
				border: none;
				a {
					padding-bottom: 0;
				}
			}
		}
		a {
			font-weight: $main-light;
			&:focus,
			&:hover {
				color: $brand-accent;
			}
		}
	}
	.third-level {
		margin: 0 0 15px 15px;
		li {
			font-size: rem-calc(18);
			line-height: 1.35;
			padding-top: 0;
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
	.connect-options {
		margin: 30px 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 28px 50px;
		.left-options {
			display: flex;
			align-items: center;
		}
		.search-link button {
			width: 44px;
			height: 44px;
			margin-right: 16px;
			color: $white;
			line-height: 1;
			border: solid 2px rgba($white, .35);
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: background-color .3s;
			svg {
				height: 20px;
			}
			&:hover, &:focus {
				background-color: rgba($white, .35);
			}
		}
		.button {
			flex-shrink: 0;
			margin: 0;
			transition: background-color .25s;
			&:hover,
			&:focus {
				color: $white;
				transition: background-color .25s;
			}
		}
		.social {
			line-height: 1;
			flex-shrink: 0;
			a {
				color: $brand-alt;
				line-height: 1;
				&:hover, &:focus {
					color: $white;
				}
			}
			svg {
				width: auto;
				max-height: 24px;
				fill: currentColor;
			}
			li {
				line-height: 1;
				margin-left: 20px;
				display: inline-block;
				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}

.offscreen-nav-header {
	margin: 10px 0 30px 0;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	a, button {
		display: inline-block;
	}
	img {
		margin: 0;
	}
	.nav-toggle {
    	font-weight: $main-normal;
    	color: $white;
    	text-transform: uppercase;
    	display: flex;
    	align-items: center;
    	a, .nav-label {
    		color: $white;
    		margin-left: 5px;
    		cursor: pointer;
    		display: inline-block;
    		transition: color .3s;
    		&:hover, &:focus {
    			color: $brand-accent;
    		}
    	}
    	&:hover a, &:focus a {
    		color: $brand-accent;
    	}
    }
}