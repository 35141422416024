.media-object {
	max-width: 480px;
	margin: 0 auto ($content-sub-section-spacing-small - $base-spacing);
	.media {
		margin-bottom: $base-spacing;
		img {
			margin: 0;
			position: relative;
			z-index: 10;
		}
	}
	h2 {
		margin-bottom: 16px;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

@include breakpoint(900px) {
	.media-object {
		max-width: 100%;
		margin-bottom: ($content-sub-section-spacing - $base-spacing);
		display: flex;
		justify-content: space-between;
		.media {
			max-width: 400px;
			margin-left: 32px;
			flex-shrink: 0;
			order: 1;
		}
		.text {
			max-width: 480px;
		}
		&:nth-child(even) {
			.media {
				margin-left: 0;
				margin-right: 32px;
				order: 0;
			}
		}
	}
}

@include breakpoint(1000px) {
	.media-object .media {
		max-width: 500px;
	}
}

@include breakpoint(1100px) {
	.media-object .media {
		max-width: 100%;
	}
}

// Triangle background on media
.media-object {
	.media.bg-triangle .inner-media {
		position: relative;
		padding: 0 10px 10px 0;
		z-index: 0;
		&::after {
			content: "";
			background-image: url('/images/cssimg/triangle_blue.svg');
			background-position: right bottom;
			background-repeat: no-repeat;
			background-size: contain;
			width: 16%;
			height: auto;
			display: block;
			position: absolute;
			top: 20%;
			bottom: 0;
			right: 0;
			@include breakpoint(medium) {
				width: 14.5%;
				top: 27%;
			}
			@include breakpoint(large) {
				top: 28%;
			}
		}
	}
	&:nth-child(even) .media.bg-triangle .inner-media::after {
		background-image: url('/images/cssimg/triangle_yellow.svg');
	}
}


//Play video icon overlaid
.media-object .media.video-icon a {
	color: $yellow;
	position: relative;
	display: block;
	&:hover, &:focus {
		color: lighten($yellow, 30%);
	}
	.play-video {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 50;
		transform: translate(-50%, -50%);
		svg {
			filter: drop-shadow(0 0 5px rgba(0,0,0,.85));
		}
	}
}

//Media object with flexbox to align media over
//content on mobile
.media-object-flex {
	.media {
		text-align: center;
	}
}

@include breakpoint(850px) {
	.media-object-flex {
		display: flex;
		.media {
			order: 1;
			flex: 1 0 auto;
		}
		.text {
			padding-right: 32px;
		}
	}
}


//Full width section for media object
.media-object-bar {
	background: $dark-charcoal;
	color: $white;
	padding: 40px 0;
	h2 {
		color: $white;
		margin-bottom: 18px;
		.subheading {
			font-size: rem-calc(16);
			color: $white;
			text-transform: uppercase;
			line-height: 1.25;
			margin: 0 0 10px;
			display: block;
		}
	}
	.media {
		max-width: 375px;
		margin: 0 auto 40px;
		img {
			margin: 0;
		}
	}
	.text {
		max-width: 550px;
		margin: 0 auto;
		> *:last-child {
			margin-bottom: 0;
		}
		p a:hover, p a:focus {
			color: $yellow;
		}
	}
	&.narrow .inner {
		max-width: 970px;
	}
}

@include breakpoint(900px) {
	.media-object-bar {
		.inner {
			display: flex;
			justify-content: space-between;
		}
		.media {
			max-width: 100%;
			margin: 0;
			flex-shrink: 0;
		}
		.text {
			max-width: 100%;
			padding-left: 30px;
			margin: 0;
		}
	}
}


//Bar in content area
.media-object-bar.content-bar {
	margin-top: $full-section-spacing-small;
	margin-bottom: $full-section-spacing-small;
}
@include breakpoint(900px) {
	.media-object-bar.content-bar {
		.inner {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
	}
}