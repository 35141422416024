/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/


.button,
form input[type="submit"] {
	background-color: $brand-accent-ally;
	font-weight: $main-normal;
	color: $white;
	text-shadow: $text-shadow;
	text-decoration: none;
	padding: 10px 20px 10px 24px;
	margin-right: 15px;
	border: none;
	display: inline-block;
	line-height: 1.2;
	position: relative;
	z-index: 10;
	transition: background-color .25s, color .2s;
	&::after {
		content: '';
		background-color: $brand-accent-ally;
		width: 100%;
		height: 100%;
		box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.1);
		transition: background-color .25s;
		transform: skew(-18deg, 0deg);
		transform-origin: bottom left;
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		z-index: -1;
	}
	&:hover {
		background-color: darken($brand-accent-ally, 5%);
		text-decoration: none;
		cursor: pointer;
		&::after {
			background-color: darken($brand-accent-ally, 5%);
		}
	}
	&:focus {
		background-color: darken($dark-blue,5%);
		text-decoration: none;
		&::after {
			background-color: darken($dark-blue,5%);
		}
	}
}
.button.small {
	font-size: rem-calc(16);
	padding: 8px 16px;
}
.button.large {
	font-size: rem-calc(27);
}
.button.short {
	padding-top: 6px;
	padding-bottom: 6px;
}
.button.tiny {
	font-size: rem-calc(16);
	padding: 4px 8px;
}
//Button Variants
.button.yellow {
	background-color: $yellow;
	color: $dark-charcoal;
	text-shadow: none;
	transition: background-color .3s;
	&::after {
		background-color: $yellow;
		transition: background-color .3s;
	}
	&:hover,
	&:focus {
		background-color: $brand-accent;
		&::after {
			background-color: $brand-accent;
		}
	}
}

.button.charcoal {
	background-color: $charcoal;
	&::after {
		background-color: $charcoal;
	}
	&:hover {
		background-color: $brand;
		&::after {
			background-color: $brand;
		}
	}
}

//Light hovers for use on dark backgrounds
.button.light-hover {
	&:hover {
		background-color: $light-blue;
		color: $dark-blue;
		text-shadow: none;
		&::after {
			background-color: $light-blue;
		}
	}
	&:focus {
		color: darken($dark-blue,10%);
		text-shadow: none;
	}
}

//Icon buttons
.button.ico {
	padding-left: 44px;
	position: relative;
	&.ico-play::before {
		content: url('/images/icons/play_circle.svg');
		height: 20px;
		width: 20px;
		color: $white;
		fill: $white;
		display: block;
		position: absolute;
		left: 14px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.button.large.ico.ico-play {
	padding-left: 50px;
	&::before {
		width: 25px;
		height: 24px;
	}
}