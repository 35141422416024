/*----------------------------------------------------------*\
   Images

   Default image styling and various image helpers. The
   floating helpers will not float on smaller views. To
   enable an image floating on all views, use the -all
   suffixed classes.

\*----------------------------------------------------------*/

img {
	margin-bottom: 15px;
}

/*------------------------------------*\
    @group Image Helpers
\*------------------------------------*/

.img-left,
.img-right {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.img-left-all {
	float: left;
	margin-right: 20px;
}
.img-right-all {
	float: right;
	margin-left: 20px;
}
.img-full.img-center {
	margin: 0 auto 15px;
	display: block;
}

.img-shadow {
	box-shadow: 0 1px 5px 1px rgba(0,0,0,.10);
}

.img-triangle {
	margin-bottom: 20px;
	position: relative;
	padding: 0 10px 10px 0;
	z-index: 0;
	display: inline-block;
	img {
		margin: 0;
		position: relative;
		z-index: 10;
	}
	&::after {
		content: "";
		background-image: url('/images/cssimg/triangle_yellow.svg');
		background-position: right bottom;
		background-repeat: no-repeat;
		background-size: contain;
		width: 16%;
		height: auto;
		display: block;
		position: absolute;
		top: 20%;
		bottom: 0;
		right: 0;
		@include breakpoint(medium) {
			width: 14.5%;
			top: 27%;
		}
		@include breakpoint(large) {
			top: 28%;
		}
	}
	&.triangle-alt::after {
		background-image: url('/images/cssimg/triangle_blue.svg');
	}
}
@media screen and #{breakpoint(medium)} {
	.img-left {
		float: left;
		margin-right: 20px;
	}
	.img-right {
		float: right;
		margin-left: 20px;
	}
}

/* @end */