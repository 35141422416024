.news-card {
	max-width: 400px;
	background-color: $white;
	border-top: solid 10px $dark-blue;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
	padding: 20px;
	h3 {
		font-family: $main-font;
		font-size: rem-calc(20);
		font-weight: $main-normal;
		line-height: 1;
		margin-bottom: 8px;
	}
	a {
		text-decoration: none;
		transition: color .3s;
		&:hover, &:focus {
			color: $brand;
		}
	}
	.news-publication {
		font-size: rem-calc(14);
		color: $black;
		text-transform: uppercase;
		margin-bottom: 10px;
		.name {
			font-weight: $main-bold;
		}
	}
	.read-more-link {
		text-align: right;
	}
	.button {
		margin-bottom: 0;
	}
}

.news-card.block-link {
	background-color: $dark-blue;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	h3 {
		color: $white;
		font-weight: $main-light;
	}
}

.news-cards-group {
	display: grid;
	grid-column-gap: 60px;
	grid-row-gap: 32px;
	justify-content: center;
}

.news-archive-list {
	h2 {
		text-align: center;
	}
}

@include breakpoint(800px) {
	.news-card {
		max-width: 100%;
	}
	.news-cards-group {
		grid-template-columns: repeat(2, 1fr);
		justify-content: space-between;
	}
	.news-archive-list {
		h2 {
			text-align: left;
		}
	}
}

@include breakpoint(1100px) {
	.news-cards-group {
		grid-template-columns: repeat(3, 1fr);
	}
}

.press-release-callout {
	p {

	}
}