.timeline {
	position: relative;
	&::before {
		content: '';
		background: $brand-accent;
		width: 80px;
		height: 100%;
		margin-left: -1px;
		position: absolute;
		left: calc(50% - 40px);
		top: 0;
		bottom: 0;
		z-index: 1;
	}
	.timeline-decade {
		width: 78px;
		background-color: $brand-accent;
		color: $main-color;
		text-align: center;
		padding: 5px;
		margin: 0 auto;
		position: relative;
		z-index: 20;
		&:first-child {
			padding-top: 30px;
		}
	}
	.timeline-item {
		width: 80%;
		margin: 30px auto;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		z-index: 20;
		.inner-text {
			background-color: $white;
			border: solid 1px $light-aluminum;
			padding: 20px;
		}
	}
	.timeline-year {
		background-color: $light-aluminum;
		font-weight: $main-bold;
		color: $brand-alt;
		padding: 5px 20px;
	}
	.timeline-logo {
		background-color: $white;
		text-align: center;
		padding-top: 30px;
		margin-top: 70px;
		position: relative;
		z-index: 20;
		img {
			margin: 0;
		}
	}
}

@include breakpoint(medium) {
	.timeline {
		.timeline-item {
			width: calc(50% - 41px);
			text-align: left;
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 30px;
			margin-left: 0;
			margin-right: 0;
			.inner-text {
				max-width: 450px;
				padding-left: 0;
				border: none;
			}
		}
		.timeline-item.flip {
			width: calc(50% - 39px);
			margin-left: auto;
			.inner-text {
				padding-left: 20px;
				padding-right: 0;
				border-left: none;
				margin-left: auto;
			}
			.timeline-year {
				text-align: right;
				&::before {
					transform: skew(18deg);
					left: auto;
					right: -5px;
				}
			}
		}
		.timeline-year {
			padding: 3px 20px;
			line-height: 1;
			position: relative;
			&::before {
				content: '';
				background-color: $light-aluminum;
				width: 10%;
				height: 100%;
				transform: skew(-18deg);
				display: block;
				position: absolute;
				top: 0;
				left: -5px;
				z-index: -1;
			}
		}
		.timeline-logo {
			background: none;
			width: 50%;
			text-align: left;
			padding-top: 0;
			margin-left: auto;
			img {
				margin-left: 2px;
			}
		}
	}
}