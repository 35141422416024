.home-template .hero {
	display: flex;
	align-items: center;
	overflow: hidden;
	.hero-content {
		background: none;
		text-align: center;
		padding: 0;
		margin-top: auto;
		margin-bottom: 50px;
		position: relative;
		.inner-content {
			padding-top: 0;
		}
		.content-wrap {
			margin: 0 auto;
		}
		.media {
			// margin-bottom: 30px;
		}
		img {
			width: 100%;
			margin: 0;
		}
		.slogan {
			transform: translateZ(0);
			filter:drop-shadow(0 1px 3px rgba(0,0,0,.3));
		}
	}
	.hero-callout-bar {
		width: 100%;
		color: $white;
		padding: 0 20px;
		display: flex;
		position: absolute;
		left: 0;
		bottom: 25px;
		p {
			max-width: 750px;
			background-color: $brand-trans;
			font-size: rem-calc(16);
			line-height: 1.25;
			padding: 15px 25px;
			margin: 0 auto;
			display: inline-block;
		}
		b {
			font-weight: $main-bold;
		}
	}

	@include breakpoint(700px) {
		.hero-callout-bar {
			bottom: 50px;
			p {
				font-size: rem-calc(20);
				line-height: 1.45;
			}
		}
	}

	@include breakpoint(800px) {
		.hero-content {
			margin-bottom: 100px;
		}
	}

	@include breakpoint(980px) {
		.hero-content {
			.content-wrap {
				max-width: 700px;
			}
		}
	}
	@include breakpoint(1050px) {
		.hero-content {
			margin-bottom: 200px;
			.content-wrap {
				max-width: 1080px;
			}
		}
	}
}