.aspects-grid-item {
	margin: 0 0 54px;
	&:last-child {
		margin-bottom: 0;
	}
	.inner-content {
		max-width: 380px;
		margin: 0 auto;
	}
	img {
		height: 176px;
		margin: 0 auto 28px;
		display: block;
	}
	.archrock-badge {
		height: 228px;
		display: flex;
		align-items: center;
		&.archrock-badge-round img {
			height: 200px;
		}
	}
}

@include breakpoint(medium) {
	.aspects-grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.aspects-grid-item {
		max-width: 100%;
		width: 46%;
		&:nth-last-child(-n + 2) {
			margin-bottom: 0;
		}
	}
}