/*----------------------------------------------------------*\
   Headings

   The headings follow a modular scale and vertical rhythm
   that changes between small and larger views. To keep
   your headings semantic, you can apply the heading classes
   to different headings for stylistic purposes while
   still using the appropriate heading hierarchy in your
   HTML.

\*----------------------------------------------------------*/

h1, .h1 {
    font-family: $heading-font;
    font-size: rem-calc(37);
    font-weight: $heading-normal;
    color: $brand;
    line-height: 1;
    margin: 0 0 53px;
}

h2, .h2 {
    font-family: $heading-font;
    font-size: rem-calc(30);
    font-weight: $heading-normal;
    color: $brand;
    line-height: 1.1;
    margin: 0 0 27px;
}
h3, .h3 {
    font-family: $heading-font;
    font-size: rem-calc(24);
    font-weight: $heading-normal;
    color: $brand;
    line-height: 1.33;
    margin: 0 0 28px;
}
h4, .h4 {
    font-family: $heading-font;
    font-size: rem-calc(20);
    font-weight: $heading-normal;
    color: $brand;
    line-height: 1.1;
    margin: 0 0 8px;
}
h5, .h5 {
    font-family: $heading-font;
    font-size: rem-calc(16);
    font-weight: $heading-normal;
    color: $brand;
    text-transform: uppercase;
    line-height: 1.25;
    margin: 0 0 10px;
}

// Section headings with blue line centered underneath
.section-heading {
    text-align: center;
    padding-bottom: 19px;
    position: relative;
    &::after {
        content: '';
        background-color: $brand-accent;
        width: 80px;
        height: 3px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

h1.section-heading, .h1.section-heading {
    margin-bottom: 34px;
}
h2.section-heading, .h2.section-heading {
    margin-bottom: 41px;
}

// Underlined headings
.underline-heading {
    padding-bottom: 5px;
    border-bottom: solid 2px $light-aluminum;
}

h3.underline-heading, .h3.underline-heading {
    margin-bottom: 16px;
}

// Partial underlined heading
.line-heading {
    padding-bottom: 19px;
    position: relative;
    &::after {
        content: '';
        background-color: $brand-accent;
        width: 80px;
        height: 3px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

@media screen and #{breakpoint(medium)} {
	h1, .h1 {
        font-size: rem-calc(53);
        line-height: .94;
        margin-bottom: 52px;
	}

	h2, .h2 {
        font-size: rem-calc(34);
        line-height: 1;
        margin-bottom: 34px;
	}

	h3, .h3 {
        font-size: rem-calc(27);
        line-height: 1;
        margin-bottom: 41px;
	}

	h4, .h4 {
        font-size: rem-calc(22);
        line-height: 1.23;
        margin-bottom: 7px;
	}
   h5, .h5 {
        font-size: rem-calc(17);
        line-height: 1.24;
        margin-bottom: 13px;
   }

    // Section headings with blue line centered underneath
    h1.section-heading, .h1.section-heading {
        margin-bottom: 67px;
    }

    // Underlined headings
    h2.section-heading, .h2.section-heading {
        margin-bottom: 49px;
    }
    h3.underline-heading, .h3.underline-heading {
        margin-bottom: 29px;
    }
}