.where-we-work-wrapper {
	border: solid 1px $light-aluminum;

}

.map-bg {
	position: relative;
	img {
		margin: 0;
	}
	.map-image {
		position: relative;
		z-index: 10;
	}
	.location-dot {
	    position: absolute;
	    margin: 0;
	    padding: 0;
	    transform: translate(-50%, -50%);
	    z-index: 40;
	    cursor: pointer;
	    &.location-rockies {
	    	top: 33.5%;
	    	left: 26.5%;
	    	.location-dot-label {
	    		transform: translateX(-26.5%);
	    	}
	    }
	    &.location-permian {
	    	top: 65%;
	    	left: 34%;
	    	.location-dot-label {
	    		transform: translateX(-34%);
	    	}
	    }
	    &.location-south-texas {
	    	top: 82%;
	    	left: 44%;
	    	.location-dot-label {
	    		transform: translateX(-44%);
	    	}
	    }
	    &.location-east {
	    	top: 48%;
	    	left: 59.5%;
	    	.location-dot-label {
	    		transform: translateX(-59.5%);
	    	}

	    }
	    &.location-corporate {
	    	top: 78.5%;
	    	left: 50%;
	    	.location-dot-label {
	    		transform: translateX(-50%);
	    	}
	    }
	}

	.location-dot-selected {
	    z-index: 999;
	}

	.location-dot-selected .location-dot-label {
	    opacity: 1;
	    visibility: visible;
	    z-index: 100;
	}

	.location-dot-button {
	    height: 26px;
	    width: 26px;
	    line-height: 1;
	    z-index: 999;
	    position: absolute;
	    z-index: 300;
	    img {
	    	position: relative;
	    	z-index: 40;
	    }
	    &::before {
	      content: '';
	      width: 300%;
	      height: 300%;
	      background-color: #ffc600;
	      margin-left: -100%;
	      margin-top: -100%;
	      border-radius: 45px;
	      display: block;
	      position: absolute;
	      z-index: 20;
	      animation: pulse-ring 1.75s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
	    }
	    &.location-dot-button-corporate::before {
	    	background-color: #305380;
	    }
	}


	.location-dot-label {
		background: rgba($white, .95);
	    width: 550px;
	    max-width: 90vw;
	    cursor: auto;;
	    border-radius: 4px;
	    box-shadow: 0 0 4px rgba($black ,.2);
	    padding: 20px;
	    z-index: -1;
	    // pointer-events: none;
	    // user-select: none;
	    opacity: 0;
	    visibility: hidden;
	    transition: all 0.1s linear;
	    position: absolute;
	    h3 {
	    	margin-bottom: 0;
	    }
	    .subtitle {
		    color: #7f7f7f;
		    font-family: $heading-font;
		    font-weight: 700;
		    font-size: 1rem;
		    margin-bottom: 10px;
	    }
	    p {
	    	font-size: rem-calc(18);
	    	line-height: 1.3;
	    }
	    p:last-child {
	    	margin-bottom: 0;
	    }
	}

	.location-dot-header {
		margin-bottom: 10px;
		position: relative;
		padding-right: 20px;
		button {
			cursor: pointer;
			transition: color .3s;
			position: absolute;
			right: 0;
			top: 0;
			&:hover, &:focus-visible {
				color: $brand-accent;
			}
		}
		.region-icon {
			margin-bottom: 10px;
		}
	}

	.location-dot-top-left .location-dot-label {
	    top: 12px;
	    left: 0;
	}

	.location-dot-top-right .location-dot-label {
	    top: 12px;
	    left: 0;
	}

	.location-dot-bottom-right .location-dot-label {
	    top: 12px;
	    left: 0;
	}

	.location-dot-bottom-left .location-dot-label {
	    top: 12px;
	    left: 0;
	}
}

@include breakpoint(900px) {
	.map-bg {
		.location-dot {
		    &.location-rockies .location-dot-label {
	    		transform: translateX(0);
		    }
		    &.location-permian .location-dot-label {
	    		transform: translateX(0);
		    }
		    &.location-south-texas .location-dot-label {
	    		transform: translateX(0);
		    }
		    &.location-east .location-dot-label {
	    		transform: translateX(0);

		    }
		    &.location-corporate .location-dot-label {
	    		transform: translateX(0);
		    }
		}
		.location-dot-label {
			max-width: 50vw;
		}
		.location-dot-top-left .location-dot-label {
		    top: 12px;
		    left: 12px;
		}

		.location-dot-top-right .location-dot-label {
		    top: 12px;
		    left: auto;
		    bottom: auto;
		    right: -12px;
		}

		.location-dot-bottom-right .location-dot-label {
			top: auto;
			left: auto;
		    right: -12px;
		    bottom: -12px;
		}

		.location-dot-bottom-left .location-dot-label {
			top: auto;
			right: auto;
		    bottom: -12px;
		    left: 12px;
		}
	}
}

@include breakpoint(1050px) {
	.map-bg {
		.location-dot-header {
			display: flex;
			gap: 20px;
			.region-icon {
				flex-shrink: 0;
				margin: 0;
			}
			.text {
				padding-top: 8px;
			}
		}
	}
}

.map-legend {
	background: #F8F8F8;
	.map-legend-lists {
		padding: 20px 20px 4px;
		display: flex;
		flex-wrap: wrap;
	}
	.map-legend-list {
		width: 100%;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		.item {
			width: 100%;
			line-height: 1;
			margin-bottom: 16px;
			display: flex;
			align-items: center;
			&.compressors img {
				width: 12px;
			}
			&.locations img {
				width: 19px;
			}
			&.headquarters img {
				width: 26px;
			}
			&.shale,
			&.basins {
				margin-bottom: 5px;
				.img-wrap {
					margin-right: 15px;
				}
			}
		}
		.img-wrap {
			width: 40px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		img {
			margin: 0;
		}
	}
}

@include breakpoint(medium) {
	.map-legend {
		.map-legend-lists {
			justify-content: space-between;
		}
		.map-legend-list {
			width: 48%;
		}
	}
}

@include breakpoint(900px) {
	.map-legend {
		.map-legend-list {
			width: 24%;
		}
	}
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}
