.info-section {
	min-height: 400px;
	background-color: $dark-blue;
	background-repeat: no-repeat;
	background-position: center center;
	color: $white;
	@include breakpoint(medium) {
		min-height: 600px;
	}
	@include breakpoint(large) {
		min-height: 800px;
	}
	.inner-content {
		@include xy-grid-container;
		max-width: 600px;
		text-align: center;
	}
	h2 {
		font-size: rem-calc(24);
		color: $white;
		line-height: 1.25;
		margin-bottom: 60px;
	}
	h3 {
		font-size: rem-calc(30);
		color: $white;
		line-height: 1.1;
		margin-bottom: 30px;
		@include breakpoint(medium) {
			font-size: rem-calc(44);
		}
	}
	.button {
		margin-bottom: 0;
	}
	&.bg-us-map {
		background-image: url('https://archrock.imgix.net/sections/bg_dark_info_box.png?auto=format&w=640');
		background-position: center 120px;
		background-size: contain;
		@include breakpoint(medium) {
			background-image: url('https://archrock.imgix.net/sections/bg_dark_info_box.png?auto=format&w=972');
			background-position: center center;
		}
		@include breakpoint(large) {
			background-size: auto;
		}
	}
	&.bg-us-map-cut-out {
		background-image: url('https://archrock.imgix.net/sections/cut_out_map.png?auto=format&w=650');
		background-position: center 120px;
		background-size: 90% auto;
		@include breakpoint(medium) {
			background-image: url('https://archrock.imgix.net/sections/cut_out_map.png?auto=format&w=970');
			background-position: center center;
		}
		@include breakpoint(large) {
			background-size: auto;
			h2 {
				margin-bottom: 120px;
			}
		}
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.info-section.bg-us-map-cut-out {
		background-image: url('https://archrock.imgix.net/sections/cut_out_map.png?auto=format&w=650&dpr=2&q=5');
		@include breakpoint(medium) {
			background-image: url('https://archrock.imgix.net/sections/cut_out_map.png?auto=format&w=970&dpr=2&q=5');
		}
		@include breakpoint(large) {
			background-size: 970px 604px;
		}
	}
}