.team-bio {
	max-width: 950px;
	margin: 0 auto 36px;
	&:last-child {
		margin-bottom: 0;
	}
}
.team-bio-media {
	text-align: center;
	margin: 0 0 30px;
	img {
		margin: 0;
		position: relative;
		z-index: 50;
	}
	.headshot {
		margin: 0 0 30px;
		.headshot-wrapper {
			padding: 0 10px 10px 0;
			display: inline-block;
			position: relative;
			&::after {
				content: "";
				background-image: url('/images/cssimg/triangle_blue.svg');
				background-position: 100% 100%;
				background-repeat: no-repeat;
				background-size: contain;
				width: 100%;
				height: auto;
				display: block;
				position: absolute;
				top: 28%;
				bottom: 0;
				right: 0;
			}
		}
	}
}
.team-bio-heading {
	text-align: center;
	padding: 0 0 10px;
	margin: 0 0 16px;
	border-bottom: solid 4px $light-gray;
	.heading {
		margin: 0 0 7px;
	}
	.subheading {
		color: $dark-blue;
		line-height: 1.3;
		margin: 0;
		.small {
			text-transform: none;
		}
	}
}
@include breakpoint(medium) {
	.team-bio {
		margin-bottom: 46px;
	}
	.team-bio-heading {
		text-align: left;
	}
	.team-bio-text-container {
		display: flex;
	}
	.team-bio-media {
		width: 100%;
		max-width: 30%;
		margin-right: 5%;
		flex-shrink: 0;
		.headshot {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(850px) {
	.team-bio-media {
		max-width: 25%;
	}
}