/*----------------------------------------------------------*\
   Spacing Sections

   Sections can be used to increase whitespace between
   content sections as needed. The recommended method is
   wrapping the content in a div and applying the class
   with the spacing you want. The -full suffix will apply
   padding to the top and bottom which is well-suited for
   content areas that span the full width of the screen
   and use a background color.

\*----------------------------------------------------------*/

.content-section-full {
	padding: $full-section-spacing-small 0 ($full-section-spacing-small - $base-spacing);
	&.no-base {
		padding-bottom: $full-section-spacing-small;
	}
}
.intro-section {
	padding-bottom: $intro-section-spacing-small;
	&.no-base {
		padding-bottom: $intro-section-spacing-small;
	}
}
.content-section {
	padding-bottom: ($content-section-spacing-small - $base-spacing);
	&.no-base {
		padding-bottom: $content-section-spacing-small;
	}
}
.content-sub-section {
	padding-bottom: ($content-sub-section-spacing-small - $base-spacing);
	&.no-base {
		padding-bottom: $content-sub-section-spacing-small;
	}
}

@media screen and #{breakpoint(medium)} {
	.content-section-full {
		padding: $full-section-spacing 0 ($full-section-spacing - $base-spacing);;
		&.no-base {
			padding-bottom: $full-section-spacing-small;
		}
	}
	.intro-section {
		padding-bottom: $intro-section-spacing;
		&.no-base {
			padding-bottom: $intro-section-spacing-small;
		}
	}
	.content-section {
		padding-bottom: ($content-section-spacing - $base-spacing);
		&.no-base {
			padding-bottom: $content-section-spacing-small;
		}
	}
	.content-sub-section {
		padding-bottom: ($content-sub-section-spacing - $base-spacing);
		&.no-base {
			padding-bottom: $content-sub-section-spacing-small;
		}
	}
}

// Section containers (width)
.content-container {
	@include xy-grid-container;
	// @include xy-gutters;
	&.wide {
		max-width: 1500px;
	}
	&.narrow {
		max-width: 800px;
	}
	&.collapse {
		padding: 0;
	}
	&.no-pad {
		padding-left: 0;
		padding-right: 0;
	}
}

// Spacing when section is below full color bg section
.section-bottom {
	margin-bottom: $content-sub-section-spacing-small;
	@include breakpoint(medium) {
		margin-bottom: $content-sub-section-spacing;
	}
}