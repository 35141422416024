/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/
$imgcdn: 'https://archrock.imgix.net';
//Base Spacing
$base-spacing: 24px;
$contained-width: 585px;

//Font families
$sans-serif-font: Metric, 'Gill Sans', Calibri, Arial, sans-serif;
$serif-font: sans-serif;
$icon-font: 'StarterTemplate';
$heading-font: 'factoriablack', Rockwell, 'Courier Bold', Georgia, 'Times New Roman', serif;
$main-font: $sans-serif-font;
$alt-font: $heading-font;

//Font weights
$main-light: 300;
$main-normal: 500;
$main-bold: 700;
$alt-normal: 900;
$heading-normal: $alt-normal;

//Font sizes
$fsize-large: 18px;

//Styleguide colors
$brand:				 #000000;
$brand-accent:		 #00a9e0;
$brand-accent-ally:	 #0C7EB0;
$brand-alt:			 #7f7f7f;
$brand-alt-accent:	 #FFFFFF;
$dark-charcoal:		 #262626;
$dark-blue:			 #004c66;
$charcoal:			 #404040;
$deep-navy:			 #00394d;
$light-blue:		 #bfe9f7;
$light-aluminum:	 #e6e6e6;
$navy:				 #00394d;
$orange:			 #ff8200;
$red:				 #da291c;
$teal:				 #00b398;
$yellow:			 #ffc600;
$white:              #FFFFFF;
$black:              #000000;
$gray:               $brand-alt;
$off-white:			 #F7F7F7;

$colors: (
	'brand':			 $brand,
	'brand-accent':		 $brand-accent,
	'brand-alt':		 $brand-alt,
	'brand-alt-accent':	 $brand-alt-accent,
	'dark-charcoal':	 $dark-charcoal,
	'dark-blue':	 	 $dark-blue,
	'charcoal':	 	 	 $charcoal,
	'deep-navy':	 	 $deep-navy,
	'light-blue':	 	 $light-blue,
	'light-aluminum':	 $light-aluminum,
	'navy':	 			 $navy,
	'orange':	 		 $orange,
	'red':	 			 $red,
	'teal':	 			 $teal,
	'yellow':	 		 $yellow,
	'white':             $white,
	'black':             $black,
	'off-white':         $off-white
);

//Gradients, for use on backgrounds only
$gradients: (
	'white': linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%)
);

//Transparent background colors
$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.7);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $brand;

//Link colors
$link-base: #0073E6;
$link-active: darken($brand-accent, 20%);

//Social media brand colors
$social: (
	'glassdoor': 	 #00a264,
	'twitter': 		 #000000,
	'facebook': 	 #3b5998,
	'googleplus': 	 #dd4b39,
	'pinterest': 	 #cb2027,
	'linkedin': 	 #007bb6,
	'youtube': 		 #bb0000,
	'vimeo': 		 #aad450,
	'instagram': 	 #E1306C,
	'quora': 		 #a82400,
	'foursquare': 	 #0072b1,
	'stumbleupon': 	 #EB4823,
	'soundcloud': 	 #ff3a00
);

//Shadows
$text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);;
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow: 0 1px 1px rgba(0,0,0,1);

//Rounded corners
$border-round: 1000px;
$border-radius: 4px;

//Section spacing
$full-section-spacing-small: 		60px;
$content-section-spacing-small: 	90px;
$content-sub-section-spacing-small: 60px;
$intro-section-spacing-small: 		60px;
$full-section-spacing: 				90px;
$content-section-spacing: 			150px;
$content-sub-section-spacing: 		90px;
$intro-section-spacing: 			90px;

//Hero backgrounds
$hero-bg: (
	'home-orange': test
);

$hero-bg-color: (
	'home-orange': #ff9900
);