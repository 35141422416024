.gallery-grid {
    margin-top: ($content-sub-section-spacing-small - $base-spacing);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .gallery-item {
        width: 47%;
        margin: 0 0 (32px - $base-spacing);
        &:nth-last-child(-n + 2) {
            margin-bottom: 0;
        }
    }
    img {
        margin: 0 0 $base-spacing;
    }
}

@media screen and #{breakpoint(medium)} {
    .gallery-grid {
        justify-content: space-around;
        .gallery-item {
            width: 35%;
        }
    }
}

@media screen and #{breakpoint(large)} {
    .gallery-grid {
        margin-top: ($content-sub-section-spacing - $base-spacing);
        justify-content: space-between;
        .gallery-item {
            width: 21%;
            margin: 0;
        }
    }
}